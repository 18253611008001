import React, { useState, useEffect } from "react";
import { Container, Col, Row, Form } from "react-bootstrap";
import API_DOMAIN from "../config/config";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../components/Table";
import { TextInputForm } from "../components/Forms";
import Pagnation from "../components/Pagnation";
import { ClickButton } from "../components/ClickButton";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const UserTablehead = ["No", "StaffName", "Role", "Action"];

const Staff = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // New state for filtered data
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Change this value according to your requirement
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/staff.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "listStaff", // Specify the action you're performing
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        setUserData(responseData.body.staff); // Store original data
        setFilteredData(responseData.body.staff); // Set filtered data initially to all staff
      } else {
        throw new Error(responseData.head.msg || "Unknown error");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    setCurrentPage(1); // Reset to the first page when a new search is made
    if (value === "") {
      setFilteredData(userData); // If search is empty, show all staff
    } else {
      const filtered = userData.filter((staff) => {
        // Ensure both staff_name and mobile_number are treated as strings
        const staffName = staff.staff_name.toLowerCase();
        const staffMobile = String(staff.mobile_number).toLowerCase(); // Convert mobile number to string
        return (
          staffName.includes(value.toLowerCase()) || staffMobile.includes(value)
        );
      });
      setFilteredData(filtered); // Filter the data based on staff name or mobile number
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col lg="7" md="4" xs="6">
            <div className="page-nav py-3">
              <span className="nav-list">Staff</span>
            </div>
          </Col>
          <Col lg="5" md="3" xs="6" className="align-self-center text-end">
            <ClickButton
              label="Add New"
              onClick={() => navigate("/console/master/staff/create")}
            />
          </Col>
          <Col lg="3" md="5" xs="12" className="py-1">
            <Form className="form page-nav">
              <TextInputForm
                placeholder={"Name , Mobile No."}
                onChange={(e) => handleSearch(e.target.value)}
                prefix_icon={<FaMagnifyingGlass />}
                labelname={"Search"}
              />
            </Form>
          </Col>
          <Col lg={9} md={12} xs={12} className="py-2">
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={filteredData.length}
              onPageChange={handlePageChange}
            />
          </Col>

          <Col lg="12" className="px-0">
            {loading ? (
              <Spinner animation="border" variant="dark" />
            ) : (
              <TableUI
                headers={UserTablehead}
                body={filteredData.slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )}
                type="staff"
                style={{ borderRadius: "5px" }}
              />
            )}
          </Col>
          <Col lg="12">
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={filteredData.length}
              onPageChange={handlePageChange}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Staff;
