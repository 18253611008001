import React, { useState } from "react";
import { Table, Button, Dropdown } from "react-bootstrap";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import API_DOMAIN from "../config/config";

const TableUI = ({ headers, body, style, type, rowData, planViewAction }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  console.log(body);
  const handleUserViewClick = (rowData) => {
    navigate("/console/user/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleUserEditClick = (rowData) => {
    navigate("/console/user/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleUserDeleteClick = async (id) => {
    try {
      const response = await fetch(`${API_DOMAIN}/users.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "deleteUsers", // Ensure this matches the PHP action
          delete_user_id: id,
        }),
      });
      console.log(
        "response",
        JSON.stringify({
          action: "deleteUsers", // Ensure this matches the PHP action
          delete_user_id: id,
        })
      );
      if (!response.ok) {
        throw new Error("Failed to connect to the server.");
      }

      const responseData = await response.json();
      console.log("Response Data:", responseData);

      if (responseData.head && responseData.head.code === 200) {
        // Show a success message
        console.log(responseData.head.msg);

        // Refresh the user list or navigate
        navigate("/console/user");
        window.location.reload();
      } else {
        // Handle specific error cases
        console.error("Error:", responseData.head.msg);
        alert(responseData.head.msg || "Failed to delete user.");
      }
    } catch (error) {
      console.error("Error during deletion:", error.message);
      alert("An unexpected error occurred while deleting the user.");
    }
  };

  const handleCompanyViewClick = (rowData) => {
    navigate("/console/company/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleCompanyEditClick = (rowData) => {
    navigate("/console/company/create", {
      state: { type: "edit", rowData: rowData },
    });
  };

  const handleDeliveryViewClick = (rowData) => {
    navigate("/console/delivery/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleDeliveryEditClick = (rowData) => {
    navigate("/console/delivery/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleDeliveryprintClick = (rowData) => {
    navigate("/console/pdfpreview", {
      state: { type: "pdfview", rowData: rowData },
    });
  };
  const handleDeliveryDeleteClick = async (id) => {
    try {
      const response = await fetch(`${API_DOMAIN}/delivery.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          delivery_id: id,
          action: "deleteDelivery",
        }),
      });

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        navigate("/console/delivery");
        window.location.reload();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handlePayrollViewClick = (rowData) => {
    navigate("/console/payroll/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handlePayrollEditClick = (rowData) => {
    navigate("/console/payroll/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handlePayrollDeleteClick = async (id) => {
    try {
      const response = await fetch(`${API_DOMAIN}/payroll.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "deletePayroll",
          delete_Payroll_id: id,
        }),
      });

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        navigate("/console/payroll");
        window.location.reload();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFactoryViewClick = (rowData) => {
    navigate("/console/master/Factory/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleFactoryEditClick = (rowData) => {
    navigate("/console/master/Factory/create", {
      state: { type: "edit", rowData: rowData },
    });
  };

  const handleFactoryDeleteClick = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`${API_DOMAIN}/factory.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "deleteFactory",
          delete_factory_id: id,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log("responseData", responseData);

      if (responseData.head.code === 200) {
        toast.success("Factory Deleted Successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        navigate("/console/master/factory");
        window.location.reload();
      } else {
        toast.error(responseData.msg || "An error occurred", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error deleting factory:", error);
      toast.error("Error deleting factory", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
    setLoading(false);
  };

  const handleMagazineViewClick = (rowData) => {
    navigate("/console/master/magazine/create", {
      state: { type: "view", rowData: rowData },
    });
  };

  const handleUnitViewClick = (rowData) => {
    navigate("/console/master/unit/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleUnitEditClick = (rowData) => {
    navigate("/console/master/unit/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleUnitDeleteClick = async (id) => {
    try {
      setLoading(true);

      const response = await fetch(`${API_DOMAIN}/unit.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "deleteUnit",
          delete_unit_id: id,
        }),
      });
      console.log("Response :", response);

      const responseData = await response.json();
      setLoading(false);
      console.log("Response Data:", responseData);

      if (responseData.head.code === 200) {
        toast.success("Unit deleted successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        // Update the UI without reloading the page
        setTimeout(() => {
          navigate("/console/master/unit");
          window.location.reload();
        }, 2000); // Delay to show the toast
      } else {
        toast.error(responseData.head?.msg || "Failed to delete unit.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      toast.error("An error occurred. Please try again later.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleGroupViewClick = (rowData) => {
    navigate("/console/master/group/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleGroupEditClick = (rowData) => {
    navigate("/console/master/group/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleGroupDeleteClick = async (id) => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/group.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "deleteGroup", // Include the action parameter
          delete_Group_id: id,
        }),
      });

      const responseText = await response.text();
      console.log("Raw response:", responseText);

      const responseData = JSON.parse(responseText);

      setLoading(false);

      if (responseData.head.code === 200) {
        toast.success("Group deleted successfully!", {
          position: "top-center",
          autoClose: 2000,
        });
        navigate("/console/master/group");
        window.location.reload();
      } else {
        toast.error(responseData.head.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while deleting the group.", {
        position: "top-center",
        autoClose: 2000,
      });
      setLoading(false);
    }
  };

  const handleCategoryViewClick = (rowData) => {
    navigate("/console/master/category/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleCategoryEditClick = (rowData) => {
    navigate("/console/master/category/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleCategoryDeleteClick = async (id) => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/category.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "deleteCategory", // Ensure the action is sent
          delete_Category_id: id, // Send the category id to be deleted
        }),
      });

      // Check if the response body is empty
      const textResponse = await response.text();
      if (!textResponse) {
        console.error("Empty response from server");
        setLoading(false);
        return;
      }

      // Parse the response text to JSON
      const responseData = JSON.parse(textResponse);

      setLoading(false);
      if (
        responseData.head.code === 200 &&
        responseData.head.msg === "Category Deleted Successfully"
      ) {
        navigate("/console/master/category"); // Navigate after deletion
        window.location.reload(); // Reload page to reflect changes
      } else {
        // Handle failure (display appropriate message)
        console.error(responseData.head.msg);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleProductViewClick = (rowData) => {
    navigate("/console/master/Product/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleProductEditClick = (rowData) => {
    navigate("/console/master/Product/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleProductDeleteClick = async (id) => {
    try {
      setLoading(true);

      const response = await fetch(`${API_DOMAIN}/product.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "deleteProduct", // Specify the action
          delete_Product_id: id, // Pass the product ID to delete
        }),
      });

      const responseData = await response.json();

      setLoading(false);

      if (responseData.head.code === 200) {
        toast.success("Product deleted successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        // Navigate to the product list and refresh the page
        setTimeout(() => {
          navigate("/console/master/product");
          window.location.reload();
        }, 2000);
      } else {
        console.error(
          responseData.message || "Failed to delete product. Unknown error."
        );
      }
    } catch (error) {
      console.error("Error deleting product:", error.message);
      setLoading(false);
    }
  };

  const handleContractViewClick = (rowData) => {
    navigate("/console/master/Contract/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleContractEditClick = (rowData) => {
    navigate("/console/master/Contract/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleContractDeleteClick = async (id) => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/contractor.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          delete_Contractor_id: id,
          action: "deleteContractor",
        }),
      });

      const responseData = await response.json();
      console.log("response", response);
      console.log("responseData", responseData);
      setLoading(false);
      if (responseData.head.code === 200) {
        navigate("/console/master/contract");
        window.location.reload();
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };
  const handleCustomerViewClick = (rowData) => {
    navigate("/console/master/Customer/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleCustomerEditClick = (rowData) => {
    navigate("/console/master/Customer/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleCustomerDeleteClick = async (id) => {
    try {
      const response = await fetch(`${API_DOMAIN}/customer.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id, // Matching the backend key
          action: "deleteCustomer",
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Response Data:", responseData);

      if (responseData?.head?.code === 200) {
        toast.success(
          responseData.head.msg || "Customer deleted successfully!",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
          }
        );

        // Reload the page or navigate after success
        setTimeout(() => {
          navigate("/console/master/customer");
          window.location.reload();
        }, 2000);
      } else {
        toast.error(responseData.head.msg || "Failed to delete customer!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        "Unexpected error occurred. Please check your connection or try again.",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        }
      );
    }
  };

  const handleStaffViewClick = (rowData) => {
    navigate("/console/master/staff/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleStaffEditClick = (rowData) => {
    navigate("/console/master/staff/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleStaffDeleteClick = async (id) => {
    try {
      const response = await fetch(`${API_DOMAIN}/staff.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          delete_staff_id: id, // Send the correct parameter for deletion
          action: "deleteStaff",
        }),
      });

      // Log response for debugging
      console.log("Response:", response);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Response Data:", responseData);

      // Ensure response structure matches expectations
      if (
        responseData?.head?.code === 200 &&
        responseData.head.msg === "Staff Deleted Successfully"
      ) {
        toast.success(responseData.head.msg || "Staff deleted successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });

        // Reload or navigate after success
        setTimeout(() => {
          navigate("/console/master/staff");
          window.location.reload();
        }, 1000);
      } else {
        toast.error(responseData.head.msg || "Failed to delete staff!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        "Unexpected error occurred. Please check your connection or try again.",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        }
      );
    }
  };

  const handleAttendanceViewClick = (rowData) => {
    navigate("/console/attendance/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleAttendanceEditClick = (rowData) => {
    navigate("/console/attendance/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleAttendanceDeleteClick = async (id) => {
    console.log("Attendance ID Sent:", id); // Debugging Log

    try {
      const response = await fetch(`${API_DOMAIN}/attendance.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          attendance_id: id, // Ensure this matches backend
          action: "deleteAttendance",
        }),
      });
      console.log("TableUI component rendered");
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      console.log("Response:", response);
      const responseData = await response.json();
      console.log("Response Data:", responseData); // Debugging Log

      if (responseData.head.code === 200) {
        toast.success(responseData.head.msg || "Deleted successfully");
        setTimeout(() => {
          navigate("/console/attendance");
          window.location.reload();
        }, 1000);
      } else {
        throw new Error(responseData.head.msg || "Failed to delete attendance");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.message || "Unexpected error occurred.");
    }
  };

  const handleOrderFormViewClick = (rowData) => {
    navigate("/console/orderform/create", {
      state: { type: "view", rowData: rowData },
    });
  };
  const handleOrderFormEditClick = (rowData) => {
    navigate("/console/orderform/create", {
      state: { type: "edit", rowData: rowData },
    });
  };
  const handleOrderFormDeleteClick = async (id) => {
    try {
      console.log("Deleting order with ID:", id); // Debug log

      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/orderform.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "deleteOrder", // Ensure the action is sent
          delete_order_id: id,
        }),
      });

      const responseData = await response.json();
      console.log(responseData); // Log response for debugging
      setLoading(false);
      if (responseData.head.code === 200) {
        setTimeout(() => {
          navigate("/console/orderform");
          window.location.reload();
        }, 1000);
      } else {
        // Handle failure (display appropriate message)
        console.error(responseData.head.msg);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleOnlineViewClick = (rowData) => {
    navigate("/console/offlineregister/create", {
      state: { type: "online", rowData: rowData },
    });
  };

  return (
    <Table responsive="md" style={style}>
      <thead>
        <tr className="headers">
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {body.map((rowData, rowIndex) => (
          <tr key={rowIndex}>
            {type === "user" && ( // Checking if type is "USER"
              <>
                {" "}
                {/* Fragment shorthand */}
                <td>{rowIndex + 1}</td>
                <td>{rowData.Name}</td>
                <td className="Mobile_Number">{rowData.RoleSelection}</td>
                <td className="Mobile_Number">{rowData.Mobile_Number}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className="action">
                        <BiDotsVerticalRounded />
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                        onClick={() => handleUserViewClick(rowData)}
                      >
                        View
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => handleUserEditClick(rowData)}
                      >
                        Edit
                      </Dropdown.Item>
                      {loading ? (
                        <center>
                          <Spinner animation="border" variant="dark" />{" "}
                        </center>
                      ) : (
                        <Dropdown.Item
                          onClick={() => handleUserDeleteClick(rowData.user_id)}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "company" && (
              <>
                {" "}
                {/* Fragment shorthand */}
                <td>{rowData.id}</td>
                <td>{rowData.company_name}</td>
                <td className="place">{rowData.mobile_number}</td>
                <td className="place">{rowData.place}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className="action">
                        <BiDotsVerticalRounded />
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                        onClick={() => handleCompanyViewClick(rowData)}
                      >
                        View
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => handleCompanyEditClick(rowData)}
                      >
                        Edit
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "delivery" && (
              <>
                {" "}
                {/* Fragment shorthand */}
                <td>{rowIndex + 1}</td>
                <td className="product_name">{rowData.fireworks_name}</td>
                <td>{rowData.customer_name}</td>
                <td className="product_name">
                  {JSON.parse(rowData.products)[0].productName}
                </td>
                <td className="quantity">
                  {JSON.parse(rowData.products)[0].quantity}/
                  {JSON.parse(rowData.products)[0].SubUnit_type ||
                    JSON.parse(rowData.products)[0].Unit_type}
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className="action">
                        <BiDotsVerticalRounded />
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {loading ? (
                        <center>
                          <Spinner animation="border" variant="dark" />{" "}
                        </center>
                      ) : (
                        <Dropdown.Item
                          onClick={() => handleDeliveryprintClick(rowData)}
                        >
                          Print Delivery silp
                        </Dropdown.Item>
                      )}

                      <Dropdown.Item
                        onClick={() => handleDeliveryEditClick(rowData)}
                      >
                        Edit
                      </Dropdown.Item>
                      {loading ? (
                        <center>
                          <Spinner animation="border" variant="dark" />{" "}
                        </center>
                      ) : (
                        <Dropdown.Item
                          onClick={() => handleDeliveryDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "payroll" && (
              <>
                {" "}
                {/* Fragment shorthand */}
                <td>{rowIndex + 1}</td>
                <td>{rowData.contractor_name}</td>
                <td className="total">{rowData.fireworks_name}</td>
                <td className="total">
                  {JSON.parse(rowData.products).reduce(
                    (acc, product) => acc + parseFloat(product.total || 0),
                    0
                  )}
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className="action">
                        <BiDotsVerticalRounded />
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handlePayrollEditClick(rowData)}
                      >
                        Edit
                      </Dropdown.Item>
                      {loading ? (
                        <center>
                          <Spinner animation="border" variant="dark" />{" "}
                        </center>
                      ) : (
                        <Dropdown.Item
                          onClick={() => handlePayrollDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "factory" && (
              <>
                {" "}
                {/* Fragment shorthand */}
                <td>{rowIndex + 1}</td>
                <td>{rowData.factory_name}</td>
                <td className="place">{rowData.place}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className="action">
                        <BiDotsVerticalRounded />
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                        onClick={() => handleFactoryViewClick(rowData)}
                      >
                        View
                      </Dropdown.Item> */}
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <Dropdown.Item
                        onClick={() => handleFactoryEditClick(rowData)}
                      >
                        Edit
                      </Dropdown.Item>
                      {loading ? (
                        <center>
                          <Spinner animation="border" variant="dark" />{" "}
                        </center>
                      ) : (
                        <Dropdown.Item
                          onClick={() => handleFactoryDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "magazine" && (
              <>
                {" "}
                {/* Fragment shorthand */}
                <td>{rowIndex + 1}</td>
                <td>{rowData.magazine_name}</td>
                <td className="factory_name">{rowData.factory_name}</td>
                <td className="place">{rowData.place}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className="action">
                        <BiDotsVerticalRounded />
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {loading ? (
                        <center>
                          <Spinner animation="border" variant="dark" />{" "}
                        </center>
                      ) : (
                        <Dropdown.Item
                          onClick={() => handleMagazineViewClick(rowData)}
                        >
                          View
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "unit" && (
              <>
                {" "}
                {/* Fragment shorthand */}
                <td>{rowIndex + 1}</td>
                <td>{rowData.unit_type}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className="action">
                        <BiDotsVerticalRounded />
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                        onClick={() => handleUnitViewClick(rowData)}
                      >
                        View
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => handleUnitEditClick(rowData)}
                      >
                        Edit
                      </Dropdown.Item>
                      {loading ? (
                        <center>
                          <Spinner animation="border" variant="dark" />{" "}
                        </center>
                      ) : (
                        <Dropdown.Item
                          onClick={() => handleUnitDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}

            {type === "group" && (
              <>
                {" "}
                {/* Fragment shorthand */}
                <td>{rowIndex + 1}</td>
                <td>{rowData.Group_type}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className="action">
                        <BiDotsVerticalRounded />
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                        onClick={() => handleGroupViewClick(rowData)}
                      >
                        View
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => handleGroupEditClick(rowData)}
                      >
                        Edit
                      </Dropdown.Item>
                      {loading ? (
                        <center>
                          <Spinner animation="border" variant="dark" />{" "}
                        </center>
                      ) : (
                        <Dropdown.Item
                          onClick={() => handleGroupDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "category" && (
              <>
                {" "}
                {/* Fragment shorthand */}
                <td>{rowIndex + 1}</td>
                <td>{rowData.Group_type}</td>
                <td className="place">{rowData.Category_type}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className="action">
                        <BiDotsVerticalRounded />
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                        onClick={() => handleCategoryViewClick(rowData)}
                      >
                        View
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => handleCategoryEditClick(rowData)}
                      >
                        Edit
                      </Dropdown.Item>
                      {loading ? (
                        <center>
                          <Spinner animation="border" variant="dark" />{" "}
                        </center>
                      ) : (
                        <Dropdown.Item
                          onClick={() => handleCategoryDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}

            {type === "product" && ( // Rendering when type is not "USER"
              <>
                <td>{rowIndex + 1}</td>
                <td className="Category_type">{rowData.Group_type}</td>
                <td className="Category_type">{rowData.Category_type}</td>
                <td>{rowData.product_name}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className="action">
                        <BiDotsVerticalRounded />
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                        onClick={() => handleProductViewClick(rowData)}
                      >
                        View
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => handleProductEditClick(rowData)}
                      >
                        Edit
                      </Dropdown.Item>
                      {loading ? (
                        <center>
                          <Spinner animation="border" variant="dark" />{" "}
                        </center>
                      ) : (
                        <Dropdown.Item
                          onClick={() => handleProductDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "contract" && ( // Rendering when type is not "USER"
              <>
                <td>{rowIndex + 1}</td>
                <td>{rowData.contractor_name}</td>
                <td className="place">{rowData.fireworks_name}</td>
                <td className="place">{rowData.place}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className="action">
                        <BiDotsVerticalRounded />
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                        onClick={() => handleContractViewClick(rowData)}
                      >
                        View
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => handleContractEditClick(rowData)}
                      >
                        Edit
                      </Dropdown.Item>
                      {loading ? (
                        <center>
                          <Spinner animation="border" variant="dark" />{" "}
                        </center>
                      ) : (
                        <Dropdown.Item
                          onClick={() => handleContractDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "customer" && ( // Rendering when type is not "USER"
              <>
                <td>{rowIndex + 1}</td>
                <td>{rowData.name}</td>
                <td className="place">{rowData.mobile_number}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className="action">
                        <BiDotsVerticalRounded />
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                        onClick={() => handleCustomerViewClick(rowData)}
                      >
                        View
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => handleCustomerEditClick(rowData)}
                      >
                        Edit
                      </Dropdown.Item>
                      {loading ? (
                        <center>
                          <Spinner animation="border" variant="dark" />{" "}
                        </center>
                      ) : (
                        <Dropdown.Item
                          onClick={() => handleCustomerDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "staff" && ( // Rendering when type is not "USER"
              <>
                <td>{rowIndex + 1}</td>
                <td>{rowData.staff_name}</td>

                <td className="place">{rowData.role}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className="action">
                        <BiDotsVerticalRounded />
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                        onClick={() => handleCustomerViewClick(rowData)}
                      >
                        View
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => handleStaffEditClick(rowData)}
                      >
                        Edit
                      </Dropdown.Item>
                      {loading ? (
                        <center>
                          <Spinner animation="border" variant="dark" />{" "}
                        </center>
                      ) : (
                        <Dropdown.Item
                          onClick={() => handleStaffDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "attendance" && (
              <>
                <td>{rowData.key}</td>
                <td>{rowData.entry_date}</td>
                <td>{rowData.presents}</td>
                <td>{rowData.absents}</td>
                <td>{rowData.total_wages}</td>

                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className="action">
                        <BiDotsVerticalRounded />
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                        onClick={() => handleCustomerViewClick(rowData)}
                      >
                        View
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => handleAttendanceEditClick(rowData)}
                      >
                        Edit
                      </Dropdown.Item>
                      {loading ? (
                        <center>
                          <Spinner animation="border" variant="dark" />{" "}
                        </center>
                      ) : (
                        <Dropdown.Item
                          onClick={() =>
                            handleAttendanceDeleteClick(rowData.attendance_id)
                          }
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
            {type === "orderform" && (
              <>
                {" "}
                {/* Fragment shorthand */}
                <td>{rowIndex + 1}</td>
                <td>{rowData.party_name}</td>
                <td>{rowData.mobile_number}</td>
                <td className="product_name">
                  {JSON.parse(rowData.products)[0].productName}
                </td>
                <td className="quantity">
                  {JSON.parse(rowData.products)[0].quantity}/
                  {JSON.parse(rowData.products)[0].unitType}
                </td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle>
                      <Button className="action">
                        <BiDotsVerticalRounded />
                      </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item
                        onClick={() => handleCategoryViewClick(rowData)}
                      >
                        View
                      </Dropdown.Item> */}
                      <Dropdown.Item
                        onClick={() => handleOrderFormEditClick(rowData)}
                      >
                        Edit
                      </Dropdown.Item>
                      {loading ? (
                        <center>
                          <Spinner animation="border" variant="dark" />{" "}
                        </center>
                      ) : (
                        <Dropdown.Item
                          onClick={() => handleOrderFormDeleteClick(rowData.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TableUI;
