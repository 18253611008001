import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { ClickButton } from "../components/ClickButton";
import { Calender, DropDownUI, TextInputForm } from "../components/Forms";
import { IoFilter } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import jsPDF from "jspdf";
import API_DOMAIN from "../config/config";
import html2canvas from "html2canvas";
const logoImage = require("../components/sidebar/images/logo.jpeg");

const OrderFormReport = () => {
  const initialFormData = {
    customer_name: "",
    product_name: "",
    fromdate: "",
    todate: "",
  };

  const [loading, setLoading] = useState(false);
  const [stockData, setstockData] = useState([]);
  console.log("stockData", stockData);
  const [userData, setUserData] = useState([]);
  console.log(userData);
  const [formData, setFormData] = useState({ ...initialFormData });
  const [productData, setproductData] = useState([]);

  console.log(productData);
  console.log(formData);

  const setDateOfBirth = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      date: date,
    }));
  };
  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: value, // Update the specified field in formData with the provided value
    });
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/orderform.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "listOrders",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      if (responseData.head.code === 200) {
        const allOrders = responseData.body.report;
        setUserData(allOrders);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchproductData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/delivery.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listDeliveries",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      console.log("aa", response);
      const responseData = await response.json();
      // Logging the entire response data for debugging
      console.log("bb", responseData);
      if (responseData.head.code === 200) {
        let sortedData = responseData.body.deliveries;

        if (formData.fromdate && formData.todate) {
          // Convert string dates to Date objects
          const fromDate = new Date(formData.fromdate);
          const toDate = new Date(formData.todate);

          // Filter data within the date range
          sortedData = sortedData.filter((user) => {
            const createDate = new Date(user.create_at);
            return createDate >= fromDate && createDate <= toDate;
          });
        } else if (formData.fromdate) {
          // Filter data from the fromDate onwards
          const fromDate = new Date(formData.fromdate);
          sortedData = sortedData.filter((user) => {
            const createDate = new Date(user.create_at);
            return createDate >= fromDate;
          });
        } else if (formData.todate) {
          // Filter data up to the toDate
          const toDate = new Date(formData.todate);
          sortedData = sortedData.filter((user) => {
            const createDate = new Date(user.create_at);
            return createDate <= toDate;
          });
        }

        setstockData(sortedData);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchproductData();
  }, [formData]);
  const handleLoad = () => {
    fetchproductData();
    setFormData({
      customer_name: "",
      product_name: "",
      fromdate: "",
      todate: "",
    });
  };
  console.log(formData);
  function generatePDF() {
    const input = document.getElementById("table-container");
    html2canvas(input, {
      width: 1400,
      height: 1200,
      scale: 5, // Decrease scale to reduce image size
      logging: true, // Enable logging for debugging
      useCORS: true, // Enable cross-origin resource sharing
      imageTimeout: 15000, // Timeout for loading images
      allowTaint: true, // Allow taint
    })
      .then((canvas) => {
        const pdf = new jsPDF("p", "in", "letter");

        // Load company logo asynchronously
        const img = new Image();
        img.onload = function () {
          // Add company logo at the top center
          const imgWidth = 2; // Width of the logo in inches
          const imgHeight = (imgWidth / this.width) * this.height; // Maintain aspect ratio
          const logoX = (pdf.internal.pageSize.width - imgWidth) / 2; // Center horizontally
          const logoY = 0.1; // Top margin in inches
          pdf.addImage(img, "PNG", logoX, logoY, imgWidth, imgHeight);

          // Add report title below center
          let title = "Maha Vilvam Pyropark Delivery Report"; // Default title

          // Modify title based on formData
          if (
            formData.customer_name ||
            formData.product_name ||
            formData.fromDate ||
            formData.toDate
          ) {
            title = "Maha Vilvam Pyropark Delivery Report" + "\n";
            // You can customize this based on your actual data
            const extraTitles = []; // Array to store additional titles

            if (formData.customer_name)
              extraTitles.push(`Customer name: ${formData.fireworkname}`);
            if (formData.product_name)
              extraTitles.push(`products Name: ${formData.product_name}`);
            if (formData.fromDate)
              extraTitles.push(`fromDate: ${formData.fromDate}`);
            if (formData.toDate) extraTitles.push(`toDate: ${formData.toDate}`);

            if (extraTitles.length > 0) {
              title += "\n(" + extraTitles.join(", ") + ")" + "\n";
            }
          }

          const titleX = pdf.internal.pageSize.width / 2;
          const titleY = 2.5; // Below logo in inches
          pdf.setFontSize(14);
          pdf.setFont("helvetica", "bold"); // Set font style to bold
          pdf.text(titleX, titleY, title, null, null, "center");

          // Add image to PDF with fixed dimensions
          pdf.addImage(
            canvas.toDataURL("image/jpeg", 2),
            "JPEG",
            0.4,
            3,
            8.2,
            10
          ); // Decrease JPEG quality to reduce file size

          // Save PDF
          pdf.save("table.pdf");
        };

        img.onerror = function () {
          console.error("Failed to load the company logo.");
        };

        img.src = logoImage; // Set the image source
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  }

  return (
    <div className="main-content">
      <Container>
        <Row>
          <Col lg="7" md="4" xs="6" className="align-self-center py-3">
            <div className="page-nav py-3">
              <span class="nav-list">Order Form</span>
            </div>
          </Col>
          <Col lg="5" className="align-self-center py-3">
            <div className="text-end">
              {" "}
              <ClickButton onClick={generatePDF} label={<>PDF</>}></ClickButton>
            </div>
          </Col>

          <Col lg="12" className="py-4">
            <div className="wire-table">
              <Table id="table-container" bordered>
                <thead>
                  <tr>
                    <th className="text-center">S No</th>
                    <th className="text-center">Party Name</th>
                    <th className="text-center">Product</th>
                    <th className="text-center">Order</th>
                    <th className="text-center">Delivery</th>
                    <th className="text-center">Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {userData && userData.length > 0 ? (
                    userData.map((party, partyIndex) => (
                      <React.Fragment key={partyIndex}>
                        <tr>
                          <td
                            className="text-center"
                            rowSpan={party.products.length + 1}
                          >
                            {partyIndex + 1}
                          </td>
                          <td
                            className="text-center"
                            rowSpan={party.products.length + 1}
                          >
                            {party.partyName}
                          </td>
                        </tr>
                        {party.products.map((product, productIndex) => (
                          <tr key={productIndex}>
                            <td className="text-center">
                              {product.productName}
                            </td>
                            <td className="text-center">
                              {product.inQuantity} / {product.unitType}
                            </td>
                            <td className="text-center">
                              {product.outQuantity} / {product.unitType}
                            </td>
                            <td className="text-center">
                              {product.inQuantity - product.outQuantity} /{" "}
                              {product.unitType}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center" colSpan="6">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={3} className="text-end fw-bold">
                      Total
                    </td>
                    <td className="text-center fw-bold">
                      {userData.reduce(
                        (orderTotal, party) =>
                          orderTotal +
                          party.products.reduce(
                            (sum, product) => sum + product.inQuantity,
                            0
                          ),
                        0
                      )}
                    </td>
                    <td className="text-center fw-bold">
                      {userData.reduce(
                        (deliveryTotal, party) =>
                          deliveryTotal +
                          party.products.reduce(
                            (sum, product) => sum + product.outQuantity,
                            0
                          ),
                        0
                      )}
                    </td>
                    <td className="text-center fw-bold">
                      {userData.reduce(
                        (balanceTotal, party) =>
                          balanceTotal +
                          party.products.reduce(
                            (sum, product) =>
                              sum + (product.inQuantity - product.outQuantity),
                            0
                          ),
                        0
                      )}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OrderFormReport;
