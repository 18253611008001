import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Row,
  Alert,
  Modal,
  Table,
  Button,
} from "react-bootstrap";
import { TextInputForm, DropDownUI } from "../components/Forms";

import { ClickButton } from "../../src/components/ClickButton";
import Spinner from "react-bootstrap/Spinner";
import PageNav from "../components/PageNav";
import API_DOMAIN from "../config/config";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContractCreation = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  console.log(rowData);
  const [factoryData, setFactoryData] = useState([]);
  console.log("factoryData", factoryData);
  const initialState =
    type === "edit"
      ? {
          ...rowData,
          products: JSON.parse(rowData.products || "[]"),
          fireworks_name: factoryData?.[0]?.factory_name || "",
        }
      : {
          contractor_name: "",
          mobile_number: "",
          place: "",
          fireworks_name: factoryData?.[0]?.factory_name || "",
          products: [],
        };

  const [formData, setFormData] = useState(initialState);
  console.log("formData", formData);
  const [error, setError] = useState("");

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [showRadioButtons, setShowRadioButtons] = useState(false);
  const [unitOptions, setUnitOptions] = useState([]);
  const [subUnitOptions, setSubUnitOptions] = useState([]);

  const navigate = useNavigate();

  const redirectModal = () => {
    navigate("/console/user");
  };
  // Function to handle row deletion
  const handleDelete = (index) => {
    const updatedProducts = formData.products.filter(
      (product, i) => i !== index
    );
    setFormData({ ...formData, products: updatedProducts });
  };
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    if (fieldName === "product_name") {
      const selectedProduct = userData.find(
        (product) => product.product_name === value
      );
      console.log("Selected Product:", selectedProduct);
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,

        Unit_type: selectedProduct?.Unit_type || "",
        SubUnit_type: selectedProduct?.SubUnit_type || "",
      }));
    } else if (fieldName === "Unit_type") {
      // Handle "Unit" radio button click
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,
        SubUnit_type: "",
      }));
    } else if (fieldName === "SubUnit_type") {
      // Handle "Sub unit" radio button click
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: value,
        Unit_type: "",
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
    }
  };
  const [showTable, setShowTable] = useState(type === "edit" ? true : false);
  const [tableData, setTableData] = useState([]);

  console.log(tableData);

  const handleAddMore = () => {
    const {
      fireworks_name,
      product_name,

      cooly_rate,
    } = formData;

    // Validation: Check if any field is empty
    if (!fireworks_name || !product_name || !cooly_rate) {
      toast.error("Please fill out all the fields."); // Display a toast message for empty fields
      return;
    }
    setFormData((prevState) => ({
      ...prevState,
      products: [
        ...prevState.products,
        {
          fireworks_name: prevState.fireworks_name,
          product_name: prevState.product_name,
          Unit_type: prevState.Unit_type,
          SubUnit_type: prevState.SubUnit_type,
          cooly_rate: prevState.cooly_rate,
        },
      ],

      fireworks_name: factoryData?.[0]?.factory_name || "",
      product_name: "",
      Unit_type: "",
      SubUnit_type: "",
      cooly_rate: "",
    }));

    setShowTable(true);
  };

  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    // Check other fields, excluding "Unit_type" and "SubUnit_type"
    if (formData.products.length === 0) {
      toast.error(`Products cannot be empty!`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: "colored",
      });
      return; // Exit if no products are present
    }

    setLoading(true); // Show loading spinner

    try {
      const response = await fetch(`${API_DOMAIN}/contractor.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          edit_Contractor_id: type === "edit" ? rowData.id : undefined, // Include only for editing
          contractor_name: formData.contractor_name,
          mobile_number: formData.mobile_number,
          place: formData.place,
          products: formData.products, // Send the entire products array
          action: type === "edit" ? "updateContractor" : "createContractor", // Differentiate between actions
        }),
      });

      console.log(
        JSON.stringify({
          edit_Contractor_id: type === "edit" ? rowData.id : undefined,
          contractor_name: formData.contractor_name,
          mobile_number: formData.mobile_number,
          place: formData.place,
          products: formData.products,
          action: type === "edit" ? "updateContractor" : "createContractor",
        })
      );

      const responseData = await response.json();
      console.log(responseData);
      if (responseData.head.code === 200) {
        toast.success("🦄 Contract Data Successfully Added!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/master/contract");
        }, 2000);
      } else {
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-center",
        autoClose: 2000,
        theme: "colored",
      });
    } finally {
      setLoading(false); // Hide loading spinner after operation is complete
    }
  };

  const fetchfactoryData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/factory.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listfactory",
        }),
      });
      console.log("factorya", response);
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log("factoryb", responseData);
      console.log(responseData); // Logging the entire response data for debugging

      if (responseData.head.code === 200) {
        setFactoryData(responseData.body.factories);
        setFormData((prevData) => ({
          ...prevData,

          fireworks_name: responseData.body.factories[0].factory_name,
        }));
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  const handleUpdateSubmit = async () => {
    console.log("Inside handleUpdateSubmit");
    setLoading(true);
    if (formData.products.length === 0) {
      toast.error(`Products cannot be empty!`, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: "colored",
      });
      return; // Exit if no products are present
    }
    try {
      const response = await fetch(`${API_DOMAIN}/contractor.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "updateContractor",
          edit_Contractor_id: rowData.id, // Include the company ID in the request
          contractor_name: formData.contractor_name,
          mobile_number: formData.mobile_number,
          place: formData.place,

          products: formData.products,
        }),
      });
      console.log(
        JSON.stringify({
          action: "updateContractor",
          edit_Contractor_id: rowData.id, // Include the company ID in the request
          contractor_name: formData.contractor_name,
          mobile_number: formData.mobile_number,
          place: formData.place,

          products: formData.products,
        })
      );
      if (!response.ok) {
        throw new Error("Failed to update company");
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        toast.success("Contract updated successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/master/contract");
        }, 2000);
      } else {
        console.error(
          responseData.head.msg || "Unknown error occurred during update"
        );
      }
    } catch (error) {
      console.error("Error updating product:", error.message);
    }

    setLoading(false);
  };
  const [userData, setUserData] = useState([]);
  console.log(userData);

  const fetchContarctor = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/contractor.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "createContractor",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      // Logging the entire response data for debugging

      if (responseData.status === 200 && responseData.message === "Success") {
        if (type === "view") {
          const selectedProduct = responseData.data.find(
            (product) => product.product_name === rowData.product_name
          );
          if (selectedProduct) {
            setFormData((prevData) => ({
              ...prevData,
              product_name: selectedProduct.product_name,
              Unit_type: selectedProduct.Unit_type || "",
              SubUnit_type: selectedProduct.SubUnit_type || "",
            }));
            setShowRadioButtons(true); // Show radio buttons
            setUnitOptions(
              selectedProduct.Unit_type ? [selectedProduct.Unit_type] : []
            );
            setSubUnitOptions(
              selectedProduct.SubUnit_type ? [selectedProduct.SubUnit_type] : []
            );
          }
        }

        if (type === "edit") {
          const selectedProduct = responseData.data.find(
            (product) => product.product_name === rowData.product_name
          );
          if (selectedProduct) {
            setFormData((prevData) => ({
              ...prevData,
              product_name: selectedProduct.product_name,
              Unit_type:
                rowData.Unit_type === ""
                  ? formData.Unit_type
                  : rowData.Unit_type || "",
              SubUnit_type:
                rowData.SubUnit_type === ""
                  ? formData.SubUnit_type
                  : rowData.SubUnit_type || "",
            }));
            setShowRadioButtons(true); // Show radio buttons
            setUnitOptions(
              rowData.Unit_type
                ? [rowData.Unit_type]
                : [selectedProduct.Unit_type]
            );
            setSubUnitOptions(
              rowData.SubUnit_type
                ? [rowData.SubUnit_type]
                : [selectedProduct.SubUnit_type]
            );
          }
        }

        //setUserData(responseData.data);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_DOMAIN}/product.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
            action: "listProduct",
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();

        if (responseData.head.code === 200) {
          setUserData(responseData.body.products);
        } else {
          throw new Error(
            responseData.message ? responseData.message : "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      }
    };
    fetchData();
    fetchfactoryData();
    fetchContarctor();
  }, []);
  console.log(userData);

  const [GroupData, setGroupData] = useState([]);
  const [CategoryData, setCategoryData] = useState([]);
  const [UnitData, setUnitData] = useState([]);
  const fetchGroupData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/group.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData); // Logging the entire response data for debugging
      setLoading(false);
      if (responseData.status === 200 && responseData.message === "Success") {
        setGroupData(responseData.data);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchGroupData();
    fetchCategoryData();
    fetchUnitData();
  }, []);
  const fetchCategoryData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/category.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData); // Logging the entire response data for debugging
      setLoading(false);
      if (responseData.status === 200 && responseData.message === "Success") {
        setCategoryData(responseData.data);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  const fetchUnitData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/unit.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData); // Logging the entire response data for debugging
      setLoading(false);
      if (responseData.status === 200 && responseData.message === "Success") {
        setUnitData(responseData.data);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  return (
    <div className="main-content">
      <Container>
        <Row className="regular">
          <Col lg="12" md="12" xs="12" className="py-3">
            <PageNav
              pagetitle={`Contract${
                type === "view"
                  ? " view"
                  : type === "edit"
                  ? "  Update"
                  : "Creation"
              }`}
            ></PageNav>
          </Col>

          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Contractor Name"}
                labelname={"Contractor Name"}
                name="contractor_name"
                value={formData.contractor_name}
                onChange={(e) => handleChange(e, "contractor_name")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Contractor Name"}
                labelname={"Contractor Name"}
                name="contractor_name"
                value={
                  type === "view"
                    ? rowData.contractor_name
                    : formData.contractor_name
                }
                onChange={(e) => handleChange(e, "contractor_name")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Mobile Number"}
                labelname={"Mobile Number"}
                name="mobile_number"
                value={formData.mobile_number}
                onChange={(e) => handleChange(e, "mobile_number")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Mobile Number"}
                labelname={"Mobile Number"}
                name="mobile_number"
                value={
                  type === "view"
                    ? rowData.mobile_number
                    : formData.mobile_number
                }
                onChange={(e) => handleChange(e, "mobile_number")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Place"}
                labelname={"Place"}
                name="place"
                value={formData.place}
                onChange={(e) => handleChange(e, "place")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Place"}
                labelname={"Place"}
                name="place"
                value={type === "view" ? rowData.place : formData.place}
                onChange={(e) => handleChange(e, "place")}
              ></TextInputForm>
            )}
          </Col>
          {/* <Col lg="3" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <div className="d-flex">
                <div className="w-250">
                  <DropDownUI
                    optionlist={factoryData.map((user) => ({
                      value: user.factory_name,
                      label: user.factory_name,
                    }))}
                    placeholder="FireWorksName"
                    labelname="FireWorksName"
                    name="fireworks_name"
                    value={formData.fireworks_name}
                    onChange={(updatedFormData) =>
                      setFormData({
                        ...formData,
                        fireworks_name: updatedFormData.fireworks_name,
                      })
                    }
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex">
                <div className="w-250">
                  <DropDownUI
                    optionlist={factoryData.map((user) => ({
                      value: user.factory_name,
                      label: user.factory_name,
                    }))}
                    placeholder="FireWorksName"
                    labelname="FireWorksName"
                    name="fireworks_name"
                    value={
                      type === "view"
                        ? rowData.fireworks_name
                        : formData.fireworks_name
                    }
                    onChange={(updatedFormData) =>
                      setFormData({
                        ...formData,
                        fireworks_name: updatedFormData.fireworks_name,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </Col> */}

          <Col lg="4" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <div className="d-flex">
                <div className="w-250">
                  <DropDownUI
                    optionlist={userData.map((user) => ({
                      value: user.product_name,
                      label: user.product_name,
                    }))}
                    labelname={"Product Name"}
                    placeholder="Product Name"
                    name="product_name"
                    value={formData.product_name}
                    onChange={(updatedFormData) => {
                      setFormData({
                        ...formData,
                        product_name: updatedFormData.product_name,
                      });

                      const selectedProduct = userData.find(
                        (user) =>
                          user.product_name === updatedFormData.product_name
                      );
                      const showRadioButtons = !!selectedProduct; // If selectedProduct is truthy, showRadioButtons will be true
                      setShowRadioButtons(showRadioButtons);
                      setUnitOptions(
                        selectedProduct?.Unit_type
                          ? [selectedProduct.Unit_type]
                          : []
                      );
                      setSubUnitOptions(
                        selectedProduct?.SubUnit_type
                          ? [selectedProduct.SubUnit_type]
                          : []
                      );
                      // Set the state to conditionally render the radio buttons
                    }}
                    showRadioButtons={showRadioButtons}
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex">
                <div className="w-250">
                  <DropDownUI
                    optionlist={userData.map((user) => ({
                      value: user.product_name,
                      label: user.product_name,
                    }))}
                    labelname={"Product Name"}
                    placeholder="Product Name"
                    name="product_name"
                    value={
                      type === "view"
                        ? rowData.product_name
                        : formData.product_name
                    }
                    // onChange={(updatedFormData) => setFormData({ ...formData, product_name: updatedFormData.product_name })}
                    onChange={(updatedFormData) => {
                      setFormData({
                        ...formData,
                        product_name: updatedFormData.product_name,
                      });

                      const selectedProduct = userData.find(
                        (user) =>
                          user.product_name === updatedFormData.product_name
                      );
                      const showRadioButtons = !!selectedProduct; // If selectedProduct is truthy, showRadioButtons will be true
                      setShowRadioButtons(showRadioButtons);
                      setUnitOptions(
                        selectedProduct?.Unit_type
                          ? [selectedProduct.Unit_type]
                          : []
                      );
                      setSubUnitOptions(
                        selectedProduct?.SubUnit_type
                          ? [selectedProduct.SubUnit_type]
                          : []
                      );
                      // Set the state to conditionally render the radio buttons
                    }}
                    showRadioButtons={showRadioButtons}
                  />
                </div>
              </div>
            )}
            {showRadioButtons && (
              <Row>
                <Col lg="6" md="6" xs="6" className="py-1">
                  <div className="text-center">
                    {unitOptions.map((option, index) => (
                      <React.Fragment key={index}>
                        <input
                          type="radio"
                          id={`unit_${index}`}
                          name="Unit_type"
                          value={option}
                          checked={formData.Unit_type === option}
                          onChange={(e) => handleChange(e, "Unit_type")}
                        />
                        <label htmlFor={`unit_${index}`}>{option}</label>
                      </React.Fragment>
                    ))}
                  </div>
                </Col>
                <Col lg="6" md="6" xs="6" className="py-1">
                  <div>
                    {subUnitOptions.map((option, index) => (
                      <React.Fragment key={index}>
                        <input
                          type="radio"
                          id={`subUnit_${index}`}
                          name="SubUnit_type"
                          value={option}
                          checked={formData.SubUnit_type === option}
                          onChange={(e) => handleChange(e, "SubUnit_type")}
                        />
                        <label htmlFor={`subUnit_${index}`}>{option}</label>
                      </React.Fragment>
                    ))}
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          <Col lg="2" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Colly Rate"}
                labelname={"Colly Rate"}
                name="cooly_rate"
                value={formData.cooly_rate}
                onChange={(e) => handleChange(e, "cooly_rate")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Colly Rate"}
                labelname={"Colly Rate"}
                name="cooly_rate"
                value={
                  type === "view" ? rowData.cooly_rate : formData.cooly_rate
                }
                onChange={(e) => handleChange(e, "cooly_rate")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="12" md="12" xs="12">
            <ClickButton
              label={<>AddMore</>}
              onClick={handleAddMore}
            ></ClickButton>
          </Col>
          <Col lg="12" md="12" xs="12" className="py-5 align-self-center">
            <div className="text-center">
              {type === "view" ? (
                <ClickButton
                  label={<>back</>}
                  onClick={() => navigate("/console/user")}
                ></ClickButton>
              ) : (
                <>
                  {type === "edit" ? (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Update</>}
                          onClick={handleUpdateSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/user")}
                        ></ClickButton>
                      </span>
                    </>
                  ) : (
                    <>
                      {/* ToastContainer always displayed */}
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />

                      {loading ? (
                        <center>
                          {/* Show Spinner while loading */}
                          <Spinner animation="border" variant="dark" />
                        </center>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          <span className="mx-2">
                            <ClickButton
                              label={<> Submit</>}
                              onClick={handleSubmit}
                              disabled={loading} // Button disabled when loading
                            ></ClickButton>
                          </span>
                          <span className="mx-2">
                            <ClickButton
                              label={<>Cancel</>}
                              onClick={() => navigate("/console/user")}
                            ></ClickButton>
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
        {error && (
          <Alert variant="danger" className="error-alert">
            {error}
          </Alert>
        )}
      </Container>
      <Row>
        <Col className="py-2">
          {showTable && (
            <Table striped bordered hover className="table-fixed">
              <thead>
                <tr>
                  <th>Contractor Name</th>
                  <th>Mobile Number</th>
                  <th>Place</th>
                  {/* <th>Fireworks Name</th> */}
                  <th>Product Name</th>
                  <th>Unit Type</th>
                  <th>SubUnit Type</th>
                  <th>Cooly Rate</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {formData.products.map((product, index) => (
                  <tr key={index}>
                    <td>{formData.contractor_name}</td>
                    <td>{formData.mobile_number}</td>
                    <td>{formData.place}</td>
                    {/* <td>{product.fireworks_name}</td> */}
                    <td>{product.product_name}</td>
                    <td>{product.Unit_type}</td>
                    <td>{product.SubUnit_type}</td>
                    <td>{product.cooly_rate}</td>
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleDelete(index)}
                        size="sm"
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>

      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        centered
      >
        <Modal.Body className="text-center">
          <img
            src={require("../components/sidebar/images/output-onlinegiftools.gif")}
            alt="Success GIF"
          />
          <p>User saved successfully!</p>
        </Modal.Body>
        <Modal.Footer>
          <ClickButton
            variant="secondary"
            label={<> Close</>}
            onClick={() => redirectModal()}
          >
            Close
          </ClickButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ContractCreation;
