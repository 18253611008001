import React, { useState, useEffect } from "react";
import { Col, Container, Row, Alert, Modal } from "react-bootstrap";
import { TextInputForm, DropDownUI } from "../components/Forms";
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import { InstantCreate, ClickButton } from "../../src/components/ClickButton";
import { BiPlus } from "react-icons/bi";
import PageNav from "../components/PageNav";
import API_DOMAIN from "../config/config";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

const DropList = [
  {
    value: "Finished",
    label: "Finished",
  },
  {
    value: "Semi Finished",
    label: "Semi Finished",
  },
];
const UnitList = [
  {
    value: "KG",
    label: "KG",
  },
  {
    value: "Gram",
    label: "Gram",
  },
  {
    value: "Drum",
    label: "Drum",
  },
  {
    value: "Case",
    label: "Case",
  },
  {
    value: "Piece",
    label: "Piece",
  },
  {
    value: "Unit",
    label: "Unit",
  },
  {
    value: "Num",
    label: "Num",
  },
  {
    value: "Box",
    label: "Box",
  },
];
const SubUnitList = [
  {
    value: "KG",
    label: "KG",
  },
  {
    value: "Gram",
    label: "Gram",
  },
  {
    value: "Drum",
    label: "Drum",
  },
  {
    value: "Case",
    label: "Case",
  },
  {
    value: "Piece",
    label: "Piece",
  },
  {
    value: "Unit",
    label: "Unit",
  },
  {
    value: "Num",
    label: "Num",
  },
  {
    value: "Box",
    label: "Box",
  },
];

const CategoryList = [
  {
    value: "Finished",
    label: "Finished",
  },
  {
    value: "Semi Finished",
    label: "Semi Finished",
  },
];

const ProductCreation = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const initialState =
    type === "edit"
      ? { ...rowData }
      : {
          Group_type: "",
          Category_type: "",
          product_name: "",
          Unit_type: "",
          SubUnit_type: "",
          Sub_count: "",
        };
  const [formData, setFormData] = useState(initialState);

  const initialStateModal =
    type === "edit"
      ? { ...rowData }
      : {
          //edit_factory_id: '',
          Group_type: "",
          Category_type: "",
        };
  const [formDataModal, setFormDataModal] = useState(initialStateModal);
  const handleInputChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setFormDataModal({
      ...formDataModal,
      [fieldName]: value,
    });
  };
  console.log(formDataModal);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [error, setError] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const handleSaveUserSuccess = () => {
    setShowSuccessModal(true);
  };

  const redirectModal = () => {
    navigate("/console/user");
  };

  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };
  const [showAlert, setShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [GroupData, setGroupData] = useState([]);
  const [CategoryData, setCategoryData] = useState([]);
  const [UnitData, setUnitData] = useState([]);
  const fetchGroupData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/group.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listGroups",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData); // Logging the entire response data for debugging
      setLoading(false);
      console.log("b", responseData);
      if (responseData.head.code === 200) {
        setGroupData(responseData.body.groups);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  const fetchCategoryData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/category.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "listCategories",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log("v", responseData); // Logging the entire response data for debugging
      setLoading(false);
      if (responseData.status === 200 && responseData.message === "Success") {
        setCategoryData(responseData.data);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  const fetchUnitData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/unit.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listUnit",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log("m", responseData); // Logging the entire response data for debugging
      setLoading(false);
      if (responseData.head.code === 200) {
        setUnitData(responseData.body.units);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUnitData();
    fetchCategoryData();
    fetchGroupData();
  }, []);
  const handleSubmit = async () => {
    // Validate form data fields
    for (const key in formData) {
      if (formData[key] === "") {
        toast.error(`${key} cannot be empty!`, {
          position: "top-center",
          autoClose: 2000,
          theme: "colored",
        });
        return; // Exit if any field is empty
      }
    }

    setLoading(true); // Show loading spinner

    const payload = {
      ...formData,
      action: "createProduct", // Action for backend
    };

    try {
      const response = await fetch(`${API_DOMAIN}/product.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      console.log("Payload sent:", payload);

      const responseData = await response.json();
      console.log("Response data:", responseData);

      if (responseData.status === 200) {
        toast.success("🦄 Product Data Successfully Added!", {
          position: "top-center",
          autoClose: 2000,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/master/product");
        }, 2000);
      } else {
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-center",
        autoClose: 2000,
        theme: "colored",
      });
    } finally {
      setLoading(false); // Hide loading spinner after operation is complete
    }
  };

  const handleUpdateSubmit = async () => {
    console.log("Inside handleUpdateSubmit");
    setLoading(true);

    try {
      const response = await fetch(`${API_DOMAIN}/product.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "updateProductInfo", // Specify the action
          edit_Product_id: rowData.id, // Include the product ID to update
          Group_type: formData.Group_type,
          Category_type: formData.Category_type,
          product_name: formData.product_name,
          Unit_type: formData.Unit_type,
          SubUnit_type: formData.SubUnit_type,
          Sub_count: formData.Sub_count,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update product");
      }

      const responseData = await response.json();

      if (responseData.status === 200) {
        toast.success("Product updated successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/master/product");
        }, 2000);
      } else {
        console.error(
          responseData.message || "Unknown error occurred during update"
        );
      }
    } catch (error) {
      console.error("Error updating product:", error.message);
    }

    setLoading(false);
  };

  const saveFactoryName = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/category.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Group_type: formDataModal.Group_type,
          Category_type: formDataModal.Category_type,
        }),
      });
      const responseData = await response.json();

      console.log(responseData);
      console.log(
        JSON.stringify({
          Group_type: formDataModal.Group_type,
          Category_type: formDataModal.Category_type,
        })
      );
      if (responseData.status === 200) {
        toast.success("🦄Category NameInstand Data Successfully Added!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {}, 1000);
      } else {
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleModalSubmit = () => {
    // Update factoryData state with the new value entered in the modal
    const newFactoryName = formDataModal.Category_type;
    setCategoryData((prevState) => [
      ...prevState,
      { Category_type: newFactoryName },
    ]);
    setFormData((prevState) => ({
      ...prevState,
      Category_type: newFactoryName,
    }));
    handleCloseModal();
    saveFactoryName();
  };
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_DOMAIN}/group.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        console.log(responseData); // Logging the entire response data for debugging
        setLoading(false);
        if (responseData.status === 200 && responseData.message === "Success") {
          setUserData(responseData.data);
        } else {
          throw new Error(
            responseData.message ? responseData.message : "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="main-content">
      <Container>
        <Row className="regular">
          <Col lg="12" md="12" xs="12" className="py-3">
            <PageNav
              pagetitle={`Product${
                type === "view"
                  ? " view"
                  : type === "edit"
                  ? "  Update"
                  : "Creation"
              }`}
            ></PageNav>
          </Col>
          <Col lg="4" md="6" xs="12" className="py-3">
            {type === "edit" ? (
              <DropDownUI
                optionlist={GroupData.map((product) => ({
                  value: product.Group_type,
                  label: product.Group_type,
                }))}
                placeholder="Group"
                labelname={"Group"}
                name="Group_type"
                value={formData.Group_type}
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    Group_type: updatedFormData.Group_type,
                  })
                }
              ></DropDownUI>
            ) : (
              <DropDownUI
                optionlist={GroupData.map((product) => ({
                  value: product.Group_type,
                  label: product.Group_type,
                }))}
                placeholder="Group"
                labelname={"Group"}
                name="Group_type"
                value={
                  type === "view" ? rowData.Group_type : formData.Group_type
                }
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    Group_type: updatedFormData.Group_type,
                  })
                }
              ></DropDownUI>
            )}
          </Col>
          <Col lg="4" md="6" xs="12" className="py-3">
            {type === "edit" ? (
              <div className="d-flex">
                <div className="w-250">
                  <DropDownUI
                    optionlist={CategoryData.map((product) => ({
                      value: product.Category_type,
                      label: product.Category_type,
                    }))}
                    placeholder="Category"
                    labelname={"Category"}
                    name="Category_type"
                    value={formData.Category_type}
                    onChange={(updatedFormData) =>
                      setFormData({
                        ...formData,
                        Category_type: updatedFormData.Category_type,
                      })
                    }
                  ></DropDownUI>
                </div>
              </div>
            ) : (
              <div className="d-flex">
                <div className="w-250">
                  <DropDownUI
                    optionlist={CategoryData.map((product) => ({
                      value: product.Category_type,
                      label: product.Category_type,
                    }))}
                    placeholder="Category"
                    labelname={"Category"}
                    name="Category_type"
                    value={
                      type === "view"
                        ? rowData.Category_type
                        : formData.Category_type
                    }
                    onChange={(updatedFormData) =>
                      setFormData({
                        ...formData,
                        Category_type: updatedFormData.Category_type,
                      })
                    }
                  ></DropDownUI>
                </div>
              </div>
            )}
          </Col>
          <Col lg="4" md="6" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Product Name"}
                labelname={"Product Name"}
                name="product_name"
                value={formData.product_name}
                onChange={(e) => handleChange(e, "product_name")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Product Name"}
                labelname={"Product Name"}
                name="product_name"
                value={
                  type === "view" ? rowData.product_name : formData.product_name
                }
                onChange={(e) => handleChange(e, "product_name")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="3" md="6" xs="12" className="py-3">
            {type === "edit" ? (
              <DropDownUI
                optionlist={UnitData.map((product) => ({
                  value: product.unit_type,
                  label: product.unit_type,
                }))}
                placeholder="Unittype"
                labelname="Unittype"
                name="Unit_type"
                value={formData.Unit_type}
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    Unit_type: updatedFormData.Unit_type,
                  })
                }
              />
            ) : (
              <DropDownUI
                optionlist={UnitData.map((product) => ({
                  value: product.unit_type,
                  label: product.unit_type,
                }))}
                placeholder="Unittype"
                labelname="Unittype"
                name="Unit_type"
                value={type === "view" ? rowData.Unit_type : formData.Unit_type}
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    Unit_type: updatedFormData.Unit_type,
                  })
                }
              />
            )}
          </Col>
          <Col lg="4" md="6" xs="12" className="py-3">
            {type === "edit" ? (
              <DropDownUI
                optionlist={UnitData.map((product) => ({
                  value: product.unit_type,
                  label: product.unit_type,
                }))}
                placeholder="Sub Unit"
                labelname="Sub Unit"
                name="SubUnit_type"
                value={formData.SubUnit_type}
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    SubUnit_type: updatedFormData.SubUnit_type,
                  })
                }
              />
            ) : (
              <DropDownUI
                optionlist={UnitData.map((product) => ({
                  value: product.unit_type,
                  label: product.unit_type,
                }))}
                placeholder="Sub Unit"
                labelname="Sub Unit"
                name="SubUnit_type"
                value={
                  type === "view" ? rowData.SubUnit_type : formData.SubUnit_type
                }
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    SubUnit_type: updatedFormData.SubUnit_type,
                  })
                }
              />
            )}
          </Col>

          <Col lg="4" md="6" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"SubCount"}
                labelname={"SubCount"}
                name="Sub_count"
                value={formData.Sub_count}
                onChange={(e) => handleChange(e, "Sub_count")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"SubCount"}
                labelname={"SubCount"}
                name="Sub_count"
                value={type === "view" ? rowData.Sub_count : formData.Sub_count}
                onChange={(e) => handleChange(e, "Sub_count")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="12" md="12" xs="12" className="py-5 align-self-center">
            <div className="text-center">
              {type === "view" ? (
                <ClickButton
                  label={<>back</>}
                  onClick={() => navigate("/console/user")}
                ></ClickButton>
              ) : (
                <>
                  {type === "edit" ? (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Update</>}
                          onClick={handleUpdateSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/user")}
                        ></ClickButton>
                      </span>
                    </>
                  ) : (
                    <>
                      {/* ToastContainer always displayed */}
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />

                      {loading ? (
                        <center>
                          {/* Show Spinner while loading */}
                          <Spinner animation="border" variant="dark" />
                        </center>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          <span className="mx-2">
                            <ClickButton
                              label={<> Submit</>}
                              onClick={handleSubmit}
                              disabled={loading} // Button disabled when loading
                            ></ClickButton>
                          </span>
                          <span className="mx-2">
                            <ClickButton
                              label={<>Cancel</>}
                              onClick={() => navigate("/console/user")}
                            ></ClickButton>
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
        {error && (
          <Alert variant="danger" className="error-alert">
            {error}
          </Alert>
        )}
      </Container>
      <Modal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        centered
      >
        <Modal.Body className="text-center">
          <img
            src={require("../components/sidebar/images/output-onlinegiftools.gif")}
            alt="Success GIF"
          />
          <p>User saved successfully!</p>
        </Modal.Body>
        <Modal.Footer>
          <ClickButton
            variant="secondary"
            label={<> Close</>}
            onClick={() => redirectModal()}
          >
            Close
          </ClickButton>
        </Modal.Footer>
      </Modal>

      <div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <TextInputForm
                            placeholder={"Group_type"}
                            labelname={"Group_type"}
                            name="Group_type"
                            value={formDataModal.Group_type}
                            onChange={(e) => handleInputChange(e, 'Group_type')}
                        ></TextInputForm> */}
            <DropDownUI
              optionlist={userData.map((factory) => ({
                value: factory.Group_type,
                label: factory.Group_type,
              }))}
              labelname={"Group Type"}
              placeholder="Group Type"
              name="Group_type"
              value={formDataModal.Group_type}
              onChange={(updatedFormData) =>
                setFormDataModal({
                  ...formDataModal,
                  Group_type: updatedFormData.Group_type,
                })
              }
            />

            <TextInputForm
              placeholder={"Category Type"}
              labelname={"Category Type"}
              name="Category_type"
              value={formDataModal.Category_type}
              onChange={(e) => handleInputChange(e, "Category_type")}
            ></TextInputForm>
          </Modal.Body>
          <Modal.Footer>
            <ClickButton label={<>Cancel</>} onClick={handleCloseModal}>
              Close
            </ClickButton>
            <ClickButton label={<>Submit</>} onClick={handleModalSubmit}>
              Save Changes
            </ClickButton>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ProductCreation;
