import React, { useState, useEffect } from "react";
import { Col, Container, Row, Table, Button } from "react-bootstrap";
import PageNav from "../components/PageNav";
import { ClickButton } from "../components/ClickButton";
import { DropDownUI, TextInputForm } from "../components/Forms";
import { MdDeleteOutline } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import API_DOMAIN from "../config/config";
import { useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const OrderFormCreation = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { type, rowData } = location.state || {};
  console.log("rowData", rowData);
  const [userData, setUserData] = useState([]);
  const [customeruserData, setCustomerUserData] = useState([]);
  const navigate = useNavigate();
  const initialState =
    type === "edit"
      ? {
          ...rowData,
          products: JSON.parse(rowData.products || "[]"),
        }
      : {
          party_name: "",
          mobile_number: "",
          address: "",
          status: "",
          products: [{ productName: "", quantity: "" }],
        };

  const [formData, setFormData] = useState(initialState);
  console.log(formData);
  const handleInputChange = (e, fieldName, index = null) => {
    const value = e.target.value;

    if (index !== null) {
      const updatedProducts = [...formData.products];
      updatedProducts[index][fieldName] = value;

      if (fieldName === "productName") {
        // Update unit type when product name is selected
        const selectedProduct = userData.find(
          (product) => product.product_name === value
        );
        if (selectedProduct) {
          updatedProducts[index].unitType = selectedProduct.Unit_type;
        }
      }

      setFormData({ ...formData, products: updatedProducts });
    } else {
      setFormData({ ...formData, [fieldName]: value });
    }
  };

  const handleAddMore = () => {
    // Adding a new empty product row
    setFormData((prevState) => ({
      ...prevState,
      products: [...prevState.products, { productName: "", quantity: "" }],
    }));
  };

  const clearProductRow = (index) => {
    const updatedProducts = [...formData.products];
    updatedProducts[index] = { productName: "", quantity: "" };
    setFormData({ ...formData, products: updatedProducts });
  };

  const handleSubmit = async () => {
    setLoading(true); // Show spinner when the request starts

    // Ensure status is set to 'new' if not provided
    if (!formData.status) {
      formData.status = "new";
    }

    // Validate required fields
    if (!formData.party_name || !formData.address || !formData.status) {
      toast.error("All fields are required", {
        position: "top-center",
        autoClose: 2000,
      });
      setLoading(false); // Hide spinner if validation fails
      return;
    }

    try {
      const response = await fetch(`${API_DOMAIN}/orderform.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "createOrder",
          data: formData,
        }),
      });

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        toast.success("Order successfully created!", {
          position: "top-center",
          autoClose: 2000,
        });
        setTimeout(() => navigate("/console/orderform"), 2000);
      } else {
        toast.error(responseData.head.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error creating order:", error);
      toast.error("An error occurred while creating the order.", {
        position: "top-center",
        autoClose: 2000,
      });
    } finally {
      setLoading(false); // Hide spinner after request completes
    }
  };

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_DOMAIN}/product.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
            action: "listProduct",
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        console.log(responseData);
        if (responseData.head.code === 200) {
          setUserData(responseData.body.products);
          console.log(responseData);
        } else {
          throw new Error(
            responseData.message ? responseData.message : "Unknown error"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      }
    };
    fetchProductData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_DOMAIN}/customer.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            search_text: "",
            action: "listCustomers",
          }),
        });

        setLoading(false);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();

        if (responseData.head.code === 200) {
          let sortedData = responseData.body.customers;

          setCustomerUserData(sortedData);
          console.log("setUserData", responseData);
        } else {
          throw new Error(responseData.message || "Unknown error occurred");
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleDelete = (index) => {
    const updatedProducts = formData.products.filter(
      (product, i) => i !== index
    );
    setFormData({ ...formData, products: updatedProducts });
  };

  const handleUpdateSubmit = async () => {
    // Validate products data
    if (
      formData.products.some(
        (product) => !product.productName || !product.quantity
      )
    ) {
      toast.error("Each product must have a name and quantity.", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }

    // Validate other required fields
    if (
      !formData.orderform_id ||
      !formData.party_name ||
      !formData.address ||
      !formData.mobile_number ||
      !formData.status
    ) {
      toast.error("Please fill in all required fields.", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }

    try {
      // Send updateOrder request to the backend
      const response = await fetch(`${API_DOMAIN}/orderform.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "updateOrder",
          data: {
            orderform_id: formData.orderform_id,
            party_name: formData.party_name,
            address: formData.address,
            mobile_number: formData.mobile_number,
            status: formData.status,
            products: formData.products,
          },
        }),
      });
      console.log("response", response);
      const responseData = await response.json();
      console.log(responseData);

      // Handle backend response
      if (responseData.head.code === 200) {
        toast.success("Order successfully updated!", {
          position: "top-center",
          autoClose: 2000,
        });
        setTimeout(() => navigate("/console/orderform"), 2000);
      } else {
        toast.error(responseData.head.msg, {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } catch (error) {
      console.error("Error updating order:", error);
      toast.error("An error occurred while updating the order.", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  return (
    <div className="main-content">
      <Container>
        <Row className="regular">
          <Col lg="12" md="12" xs="12" className="py-3">
            <PageNav
              pagetitle={`orderform${
                type === "view"
                  ? " view"
                  : type === "edit"
                  ? "  Update"
                  : "Creation"
              }`}
            ></PageNav>
          </Col>
          <Col lg="4" md="4" className="py-3 justify-content-center">
            <DropDownUI
              optionlist={customeruserData.map((customer) => ({
                value: customer.customer_id, // Dropdown value uses customer_id
                label: customer.name, // Dropdown label shows customer name
              }))}
              labelname={"customer"}
              placeholder="Select customer" // This will show only before selection
              name="party_name"
              value={
                customeruserData.find(
                  (customer) => customer.name === formData.party_name
                )?.customer_id || ""
              } // Correctly maps name to ID
              onChange={(updatedFormData) => {
                const selectedCustomer = customeruserData.find(
                  (customer) =>
                    customer.customer_id === updatedFormData.party_name
                );

                setFormData({
                  ...formData,
                  party_name: selectedCustomer ? selectedCustomer.name : "", // Store name in formData
                  address: selectedCustomer ? selectedCustomer.address : "",
                  mobile_number: selectedCustomer
                    ? selectedCustomer.mobile_number
                    : "",
                });
              }}
            />
          </Col>

          <Col lg="4" md="4" xs="12" className="py-3">
            {/* <TextInputForm
              placeholder="Mobile Number"
              labelname="Mobile Number"
              value={formData.mobile_number} // Corrected
              onChange={(e) => handleInputChange(e, "mobile_number")} // Corrected
            /> */}
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            {/* <TextInputForm
              placeholder="address"
              labelname="address"
              value={formData.address} // Corrected
              onChange={(e) => handleInputChange(e, "address")} // Corrected
            /> */}
          </Col>
          {/* <Col lg="3" md="4" xs="12" className="py-4">
            <label>Status</label>
            <select
              className="form-cntrl w-100"
              value={formData.status}
              onChange={(e) => handleInputChange(e, "status")}
            >
              <option value="Select Values">Select Values</option>
              <option value="New">New</option>
              <option value="Pending">Pending</option>
            </select>
          </Col> */}

          <Col xs="8" className="py-3 mx-auto">
            <Table>
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {formData.products.map((product, index) => (
                  <tr key={index}>
                    <td>
                      <div className="w-75">
                        <DropDownUI
                          optionlist={userData.map((user) => ({
                            value: user.product_name,
                            label: user.product_name,
                          }))}
                          labelname={"Product Name"}
                          placeholder="Product Name"
                          name="product_name"
                          value={product.productName}
                          onChange={(updatedFormData) => {
                            const updatedProducts = [...formData.products];
                            updatedProducts[index].productName =
                              updatedFormData.product_name;
                            // Find the corresponding product to set the unit type
                            const selectedProduct = userData.find(
                              (product) =>
                                product.product_name ===
                                updatedFormData.product_name
                            );
                            if (selectedProduct) {
                              updatedProducts[index].unitType =
                                selectedProduct.Unit_type;
                            }
                            setFormData({
                              ...formData,
                              products: updatedProducts,
                            });
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="w-50">
                        <TextInputForm
                          placeholder="Quantity"
                          labelname="Quantity"
                          value={product.quantity}
                          onChange={(e) =>
                            handleInputChange(e, "quantity", index)
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <MdDeleteOutline
                        size={22}
                        onClick={() => clearProductRow(index)}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Col lg="3" md="4" xs="12" className="py-3 align-self-center">
              <div className="text-center">
                <ClickButton
                  label={<> Add More</>}
                  onClick={handleAddMore}
                  disabled={loading}
                ></ClickButton>
              </div>
            </Col>
          </Col>

          <Col lg="12" md="12" xs="12" className="py-5 align-self-center">
            <div className="text-center">
              {type === "view" ? (
                <ClickButton
                  label={<>back</>}
                  onClick={() => navigate("/console/orderform")}
                ></ClickButton>
              ) : (
                <>
                  {type === "edit" ? (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Update</>}
                          onClick={handleUpdateSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/orderform")}
                        ></ClickButton>
                      </span>
                    </>
                  ) : (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />

                      {loading ? (
                        <center>
                          {/* Show Spinner while loading */}
                          <Spinner animation="border" variant="dark" />
                        </center>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          <span className="mx-2">
                            <ClickButton
                              label={<>Submit</>}
                              onClick={handleSubmit}
                              disabled={loading} // Disable the submit button while loading
                            ></ClickButton>
                          </span>
                          <span className="mx-2">
                            <ClickButton
                              label={<>Cancel</>}
                              onClick={() => navigate("/console/orderform")}
                            ></ClickButton>
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Container>

      {formData.products.length > 0 && (
        <Container>
          <Row className="py-1">
            <Table striped bordered hover>
              <thead>
                <tr>
                  {/* <th>FireWorks Name</th> */}
                  <th>Party Name</th>
                  <th>Mobile Number</th>
                  <th>Address</th>
                  <th>Product Name</th>
                  <th>unitType</th>
                  <th>Quantity</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {formData.products.map((entry, index) => (
                  <tr key={index}>
                    {/* <td>{formData.fireWorksName}</td> */}

                    <td>{formData.party_name}</td>
                    <td>{formData.mobile_number}</td>
                    <td>{formData.address}</td>
                    <td>{entry.productName}</td>
                    <td>{entry.unitType}</td>
                    <td>{entry.quantity}</td>

                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(index)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default OrderFormCreation;
