import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { ClickButton } from "../components/ClickButton";
import { Calender, DropDownUI } from "../components/Forms";
import { IoFilter } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import jsPDF from "jspdf";
import API_DOMAIN from "../config/config";
import html2canvas from "html2canvas";
const logoImage = require("../components/sidebar/images/logo.jpeg");

const DeliveryReport = () => {
  const initialFormData = {
    customer_name: "",
    product_name: "",
    fromdate: "",
    todate: "",
  };

  const [loading, setLoading] = useState(false);
  const [stockData, setstockData] = useState([]);
  console.log("stockData", stockData);
  const [userData, setUserData] = useState([]);
  const [formData, setFormData] = useState({ ...initialFormData });
  const [productData, setproductData] = useState([]);

  console.log(productData);
  console.log(formData);

  const setDateOfBirth = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      date: date,
    }));
  };
  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: value, // Update the specified field in formData with the provided value
    });
  };
  const fetchproductbasedtData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/product.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listProduct",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      // Logging the entire response data for debugging

      if (responseData.head.code === 200) {
        let sortedData = responseData.body.products;

        setproductData(sortedData);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  const fetchproductData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/delivery.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listDeliveries",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      console.log("aa", response);
      const responseData = await response.json();
      // Logging the entire response data for debugging
      console.log("bb", responseData);
      if (responseData.head.code === 200) {
        let sortedData = responseData.body.deliveries;

        if (formData.fromdate && formData.todate) {
          // Convert string dates to Date objects
          const fromDate = new Date(formData.fromdate);
          const toDate = new Date(formData.todate);

          // Filter data within the date range
          sortedData = sortedData.filter((user) => {
            const createDate = new Date(user.create_at);
            return createDate >= fromDate && createDate <= toDate;
          });
        } else if (formData.fromdate) {
          // Filter data from the fromDate onwards
          const fromDate = new Date(formData.fromdate);
          sortedData = sortedData.filter((user) => {
            const createDate = new Date(user.create_at);
            return createDate >= fromDate;
          });
        } else if (formData.todate) {
          // Filter data up to the toDate
          const toDate = new Date(formData.todate);
          sortedData = sortedData.filter((user) => {
            const createDate = new Date(user.create_at);
            return createDate <= toDate;
          });
        }

        //   sortedData = sortedData.filter((delivery) => {
        //   // Filter by product name
        //   if (formData.product_name) {
        //     const products = JSON.parse(delivery.products || "[]");
        //     const productMatch = products.some(
        //       (product) => product.productName === formData.product_name
        //     );
        //     if (!productMatch) {
        //       return false;
        //     }
        //   }
        // });

        setstockData(sortedData);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/customer.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listCustomers",
        }),
      });

      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log(responseData);

      if (responseData.head.code === 200) {
        let sortedData = responseData.body.customers;

        setUserData(sortedData);
        console.log("Data:", responseData.data);
      } else {
        throw new Error(responseData.message || "Unknown error occurred");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    fetchproductData();
    fetchproductbasedtData();
  }, [formData]);
  const handleLoad = () => {
    fetchproductData();
    setFormData({
      customer_name: "",
      product_name: "",
      fromdate: "",
      todate: "",
    });
  };
  console.log(formData);
  function generatePDF() {
    const input = document.getElementById("table-container");
    html2canvas(input, {
      width: 1400,
      height: 1200,
      scale: 5, // Decrease scale to reduce image size
      logging: true, // Enable logging for debugging
      useCORS: true, // Enable cross-origin resource sharing
      imageTimeout: 15000, // Timeout for loading images
      allowTaint: true, // Allow taint
    })
      .then((canvas) => {
        const pdf = new jsPDF("p", "in", "letter");

        // Load company logo asynchronously
        const img = new Image();
        img.onload = function () {
          // Add company logo at the top center
          const imgWidth = 2; // Width of the logo in inches
          const imgHeight = (imgWidth / this.width) * this.height; // Maintain aspect ratio
          const logoX = (pdf.internal.pageSize.width - imgWidth) / 2; // Center horizontally
          const logoY = 0.1; // Top margin in inches
          pdf.addImage(img, "PNG", logoX, logoY, imgWidth, imgHeight);

          // Add report title below center
          let title = "Maha Vilvam Pyropark Delivery Report"; // Default title

          // Modify title based on formData
          if (
            formData.customer_name ||
            formData.product_name ||
            formData.fromDate ||
            formData.toDate
          ) {
            title = "Maha Vilvam Pyropark Delivery Report" + "\n";
            // You can customize this based on your actual data
            const extraTitles = []; // Array to store additional titles

            if (formData.customer_name)
              extraTitles.push(`Customer name: ${formData.fireworkname}`);
            if (formData.product_name)
              extraTitles.push(`products Name: ${formData.product_name}`);
            if (formData.fromDate)
              extraTitles.push(`fromDate: ${formData.fromDate}`);
            if (formData.toDate) extraTitles.push(`toDate: ${formData.toDate}`);

            if (extraTitles.length > 0) {
              title += "\n(" + extraTitles.join(", ") + ")" + "\n";
            }
          }

          const titleX = pdf.internal.pageSize.width / 2;
          const titleY = 2.5; // Below logo in inches
          pdf.setFontSize(14);
          pdf.setFont("helvetica", "bold"); // Set font style to bold
          pdf.text(titleX, titleY, title, null, null, "center");

          // Add image to PDF with fixed dimensions
          pdf.addImage(
            canvas.toDataURL("image/jpeg", 2),
            "JPEG",
            0.4,
            3,
            8.2,
            10
          ); // Decrease JPEG quality to reduce file size

          // Save PDF
          pdf.save("table.pdf");
        };

        img.onerror = function () {
          console.error("Failed to load the company logo.");
        };

        img.src = logoImage; // Set the image source
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  }

  return (
    <div className="main-content">
      <Container>
        <Row>
          <Col lg="12" className="py-4 text-end">
            <span>
              <Button onClick={handleLoad} className="filter mx-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Undo Filter
              </Button>
            </span>
          </Col>
          <Col lg="7" md="4" xs="6" className="align-self-center py-3">
            <div className="page-nav py-3">
              <span class="nav-list">Delivery Report</span>
            </div>
          </Col>
          <Col lg="5" className="align-self-center py-3">
            <div className="text-end">
              {" "}
              <ClickButton onClick={generatePDF} label={<>PDF</>}></ClickButton>
            </div>
          </Col>
          {/* <Col lg="3" className="py-4">
            <DropDownUI
              optionlist={userData.map((item) => ({
                label: item.name,
                value: item.name,
              }))}
              placeholder="Customer Name"
              labelname="CustomerName"
              name="customer_name"
              value={formData.customer_name}
              onChange={(updatedFormData) =>
                setFormData({
                  ...formData,
                  customer_name: updatedFormData.customer_name,
                })
              }
            ></DropDownUI>
          </Col> */}
          {/* <Col lg="3" className="py-4">
            <DropDownUI
              optionlist={productData.map((item) => ({
                label: item.product_name,
                value: item.product_name,
              }))}
              placeholder="Product Name"
              labelname="Product Name"
              name="product_name"
              value={formData.productsName}
              onChange={(updatedFormData) =>
                setFormData({
                  ...formData,
                  product_name: updatedFormData.product_name,
                })
              }
            ></DropDownUI>
          </Col> */}
          <Col lg="3" md="6" xs="12" className="py-4">
            <div>
              <Calender
                setLabel={(date) => setLabel(date, "fromdate")}
                selectedDate={formData.data}
                onSelectDate={setDateOfBirth}
                calenderlabel="From Date"
              />
            </div>
          </Col>
          <Col lg="3" md="6" xs="12" className="py-4">
            <div>
              <Calender
                setLabel={(date) => setLabel(date, "todate")}
                selectedDate={formData.data}
                onSelectDate={setDateOfBirth}
                calenderlabel="To Date"
              />
            </div>
          </Col>
          <Col lg="12">
            <div className="wire-table">
              <Table id="table-container">
                <thead>
                  <tr className="fw-bold">
                    <th rowSpan={2}>S No</th>
                    <th rowSpan={2}>Customer Name</th>
                    <th colSpan={2} className="text-center">
                      Total Case
                    </th>
                  </tr>
                  <tr>
                    <th>Unit</th>
                    <th>Sub Unit</th>
                  </tr>
                </thead>
                <tbody>
                  {stockData
                    .map((item) => ({
                      ...item,
                      products: JSON.parse(item.products),
                    }))
                    .reduce((acc, item) => {
                      item.products.forEach((product) => {
                        const existingIndex = acc.findIndex(
                          (entry) =>
                            entry.delivery_silp_no === item.delivery_silp_no &&
                            entry.customer_name === item.customer_name &&
                            entry.Unit_type === product.Unit_type &&
                            entry.SubUnit_type === product.SubUnit_type
                        );

                        if (existingIndex !== -1) {
                          acc[existingIndex].quantity += parseFloat(
                            product.quantity
                          );
                        } else {
                          acc.push({
                            delivery_silp_no: item.delivery_silp_no,
                            customer_name: item.customer_name,
                            quantity: parseFloat(product.quantity),
                            Unit_type: product.Unit_type || null,
                            SubUnit_type: product.SubUnit_type || null,
                          });
                        }
                      });
                      return acc;
                    }, [])
                    .map((customer, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{customer.customer_name}</td>
                        <td>
                          {customer.Unit_type
                            ? `${customer.quantity} ${customer.Unit_type}`
                            : "-"}
                        </td>
                        <td>
                          {customer.SubUnit_type
                            ? `${customer.quantity} ${customer.SubUnit_type}`
                            : "-"}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DeliveryReport;
