import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { Calender, DropDownUI } from "../components/Forms";
import { ClickButton } from "../components/ClickButton";
import { IoFilter } from "react-icons/io5";
import * as XLSX from "xlsx";
import Button from "react-bootstrap/Button";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import API_DOMAIN from "../config/config";
import html2canvas from "html2canvas";
import generatePDF from "./reactpdf";
const logoImage = require("../components/sidebar/images/logo.jpeg");
const MagazineStock = () => {
  const initialFormData = {
    fireworkname: "",
    productsName: "",
  };
  const [loading, setLoading] = useState(false);
  const [stockData, setstockData] = useState([]);
  const [showExcelButton, setShowExcelButton] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [userData, setUserData] = useState([]);
  const [deliveryuserData, setDeliveryUserData] = useState([]);
  const [factoryData, setFactoryData] = useState([]);
  const [formData, setFormData] = useState({ ...initialFormData });
  const [productData, setproductData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  console.log(productData);
  console.log(formData);
  const setDateOfBirth = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      date: date,
    }));
  };
  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: value, // Update the specified field in formData with the provided value
    });
  };
  const fetchproductbasedtData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/product.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listProduct",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      // Logging the entire response data for debugging

      if (responseData.head.code === 200) {
        let sortedData = responseData.body.products;

        setproductData(sortedData);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  const fetchfactoryData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/factory.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listfactory",
        }),
      });

      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        setFactoryData(responseData.body.factories);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  const fetchmagazinestockData = async () => {
    try {
      setLoading(true);

      // API request
      const response = await fetch(`${API_DOMAIN}/magazinestock.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listMagazineStock",
        }),
      });

      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        let sortedData = responseData.body.magazineStocks;

        // Debugging log for fetched data
        console.log(sortedData, "Fetched Deliveries");

        // Apply filters based on formData
        sortedData = sortedData.filter((delivery) => {
          // Filter by fireworks name
          if (
            formData.fireworkname &&
            delivery.fireworks_name !== formData.fireworkname
          ) {
            return false;
          }

          // Filter by date range
          const createDate = new Date(delivery.create_at);
          const fromDate = formData.fromdate
            ? new Date(formData.fromdate)
            : null;
          const toDate = formData.todate ? new Date(formData.todate) : null;

          if (
            fromDate &&
            toDate &&
            (createDate < fromDate || createDate > toDate)
          ) {
            return false;
          }
          if (fromDate && createDate < fromDate) {
            return false;
          }
          if (toDate && createDate > toDate) {
            return false;
          }

          // Filter by product name
          if (formData.productsName) {
            const products = JSON.parse(delivery.products || "[]");
            const productMatch = products.some(
              (product) => product.productName === formData.productsName
            );
            if (!productMatch) {
              return false;
            }
          }

          return true;
        });

        // Update state with filtered data
        setstockData(sortedData);
      } else {
        throw new Error(responseData.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/delivery.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: searchText,
          action: "listDeliveries",
        }),
      });

      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      console.log("delivery", setDeliveryUserData);

      if (responseData.head.code === 200) {
        setDeliveryUserData(responseData.body.deliveries);
      } else {
        throw new Error(responseData.message || "Unknown error occurred");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  const fetchPayrollData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/payroll.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "listPayrolls",
          search_text: searchText,
        }),
      });

      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        setUserData(responseData.body.payrolls);
      } else {
        throw new Error(responseData.message || "Unknown error occurred");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchmagazinestockData();
    fetchData();
    fetchPayrollData();
    fetchfactoryData();
    fetchproductbasedtData();
    refershdata();
  }, [formData]);
  const handleLoad = () => {
    fetchmagazinestockData();
    setFormData({
      fireworkname: "",
      productsName: "",
    });
  };
  const refershdata = () => {
    setCsvData([]);
    setShowExcelButton(true);
  };
  // const generateCSV = () => {
  //   // Get table data
  //   const table = document.getElementById("table-container");

  //   // Extract headers from the table
  //   const headers = [];
  //   const headerRows = table.querySelectorAll("thead tr");
  //   headerRows.forEach((row) => {
  //     const cols = Array.from(row.querySelectorAll("th"));
  //     const colTexts = cols.map((col) => col.textContent.trim());
  //     headers.push(colTexts);
  //   });

  //   // Flatten headers for CSV format
  //   const flattenedHeaders = headers.reduce((acc, row, rowIndex) => {
  //     if (rowIndex === 0) {
  //       return row;
  //     }
  //     // Fill empty columns with blanks to align columns
  //     row.forEach((col, index) => {
  //       acc[index] = acc[index] ? `${acc[index]} ${col}` : col;
  //     });
  //     return acc;
  //   }, []);

  //   // Extract rows from the table
  //   const rows = [];
  //   const bodyRows = table.querySelectorAll("tbody tr");
  //   bodyRows.forEach((row) => {
  //     const cols = Array.from(row.querySelectorAll("td"));
  //     const colTexts = cols.map((col) => col.textContent.trim());
  //     rows.push(colTexts);
  //   });

  //   // Convert to CSV format
  //   const csvContent = [
  //     flattenedHeaders.join(","), // Add headers
  //     ...rows.map((row) => row.join(",")), // Add data rows
  //   ].join("\n");

  //   // Create downloadable CSV file
  //   const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.setAttribute("download", "table_data.csv");
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const generateCSV = () => {
    // Get the table element
    const table = document.getElementById("table-container");

    // Prepare data structure for Excel
    const sheetData = [
      [
        "S No", // Column A
        "Product", // Column B
        "In",
        "", // Column C and D
        "Out",
        "", // Column E and F
        "Balance",
        "", // Column G and H
      ],
      [
        "",
        "", // Empty for merged cells
        "unit",
        "subUnit", // Subheadings for "In"
        "unit",
        "subUnit", // Subheadings for "Out"
        "unit",
        "subUnit", // Subheadings for "Balance"
      ],
    ];

    // Extract headers (both rows of the header)
    // const headerRows = table.querySelectorAll("thead tr");
    // headerRows.forEach((row) => {
    //   const cols = Array.from(row.querySelectorAll("th"));
    //   sheetData.push(cols.map((col) => col.textContent.trim())); // Push header rows to sheet data
    // });

    // Extract body rows (data rows)
    const bodyRows = table.querySelectorAll("tbody tr");
    bodyRows.forEach((row) => {
      const cols = Array.from(row.querySelectorAll("td"));
      sheetData.push(cols.map((col) => col.textContent.trim())); // Push data rows to sheet data
    });

    // Convert the extracted data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

    // Apply column widths (optional for better formatting)
    const columnWidths = [
      { wch: 5 }, // S No
      { wch: 25 }, // Product
      { wch: 10 }, // In Unit
      { wch: 10 }, // In Sub Unit
      { wch: 10 }, // Out Unit
      { wch: 10 }, // Out Sub Unit
      { wch: 15 }, // Balance Unit
      { wch: 15 }, // Balance Sub Unit
    ];
    worksheet["!cols"] = columnWidths;

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table Data");

    // Write the workbook to a file and trigger download
    XLSX.writeFile(workbook, "table_data.xlsx");
  };

  function generatePDF() {
    const input = document.getElementById("table-container");
    html2canvas(input, {
      width: 1400,
      height: 1200,
      scale: 5, // Decrease scale to reduce image size
      logging: true, // Enable logging for debugging
      useCORS: true, // Enable cross-origin resource sharing
      imageTimeout: 15000, // Timeout for loading images
      allowTaint: true, // Allow taint
    })
      .then((canvas) => {
        const pdf = new jsPDF("p", "in", "letter");

        // Load company logo asynchronously
        const img = new Image();
        img.onload = function () {
          // Add company logo at the top center
          const imgWidth = 2; // Width of the logo in inches
          const imgHeight = (imgWidth / this.width) * this.height; // Maintain aspect ratio
          const logoX = (pdf.internal.pageSize.width - imgWidth) / 2; // Center horizontally
          const logoY = 0.1; // Top margin in inches
          pdf.addImage(img, "PNG", logoX, logoY, imgWidth, imgHeight);

          // Add report title below center
          let title = "Maha Vilvam Pyropark Magazine Report"; // Default title

          // Modify title based on formData
          if (
            formData.fireworkname ||
            formData.productsName ||
            formData.fromDate ||
            formData.toDate
          ) {
            title = "Maha Vilvam Pyropark Magazine Report" + "\n";
            // You can customize this based on your actual data
            const extraTitles = []; // Array to store additional titles

            if (formData.fireworkname)
              extraTitles.push(`fireworkname: ${formData.fireworkname}`);
            if (formData.productsName)
              extraTitles.push(`productsName: ${formData.productsName}`);
            if (formData.fromDate)
              extraTitles.push(`fromDate: ${formData.fromDate}`);
            if (formData.toDate) extraTitles.push(`toDate: ${formData.toDate}`);

            if (extraTitles.length > 0) {
              title += "\n(" + extraTitles.join(", ") + ")" + "\n";
            }
          }

          const titleX = pdf.internal.pageSize.width / 2;
          const titleY = 2.5; // Below logo in inches
          pdf.setFontSize(14);
          pdf.setFont("helvetica", "bold"); // Set font style to bold
          pdf.text(titleX, titleY, title, null, null, "center");

          // Add image to PDF with fixed dimensions
          pdf.addImage(
            canvas.toDataURL("image/jpeg", 2),
            "JPEG",
            0.4,
            3,
            8.2,
            10
          ); // Decrease JPEG quality to reduce file size

          // Save PDF
          pdf.save("table.pdf");
        };

        img.onerror = function () {
          console.error("Failed to load the company logo.");
        };

        img.src = logoImage; // Set the image source
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  }
  console.log("stockData", stockData);
  const aggregatedData = stockData
    .flatMap((entry) => JSON.parse(entry.products)) // Parse JSON strings and flatten products
    .reduce((acc, item) => {
      const key = `${item.productName}-${item.unitType || ""}-${
        item.subUnitType || ""
      }`; // Unique key based on type
      if (acc[key]) {
        acc[key].caseCount += parseFloat(item.caseCount); // Aggregate case count
      } else {
        acc[key] = {
          productName: item.productName,
          unitType: item.unitType || null,
          subUnitType: item.subUnitType || null,
          caseCount: parseFloat(item.caseCount),
        };
      }
      return acc;
    }, {});

  // Convert object to array
  // Update the aggregatedData to include "In" case count from payroll data
  const aggregatedList = Object.values(aggregatedData).map((product) => {
    // Initialize "In" case count values
    let inUnitCount = 0;
    let inSubUnitCount = 0;
    let outUnitCount = 0;
    let outSubUnitCount = 0;

    // Iterate over payroll data and check for matching product names
    userData.forEach((payroll) => {
      const products = JSON.parse(payroll.products || "[]");
      products.forEach((payrollProduct) => {
        // If the product name matches, add to the "In" count
        if (payrollProduct.productName === product.productName) {
          if (payrollProduct.unitType === product.unitType) {
            inUnitCount += parseFloat(payrollProduct.caseCount);
          }
          if (payrollProduct.subUnitType === product.subUnitType) {
            inSubUnitCount += parseFloat(payrollProduct.caseCount);
          }
        }
      });
    });
    console.log("bbb", userData);
    // Iterate over payroll data and check for matching product names
    deliveryuserData.forEach((delivery) => {
      const products = JSON.parse(delivery.products || "[]");
      products.forEach((deliveryProduct) => {
        // If the product name matches, add to the "In" count
        if (deliveryProduct.productName === product.productName) {
          if (deliveryProduct.Unit_type === product.unitType) {
            outUnitCount += parseFloat(deliveryProduct.quantity);
          }
          if (deliveryProduct.SubUnit_type === product.unitType) {
            outSubUnitCount += parseFloat(deliveryProduct.quantity);
          }
        }
      });
    });
    console.log("delivery", deliveryuserData);

    return {
      ...product,
      inUnitCount,
      inSubUnitCount,
      outUnitCount,
      outSubUnitCount,
    };
  });

  return (
    <div>
      <div className="main-content">
        <Container>
          <Row>
            <Col lg="12" className="py-4 text-end">
              <span>
                <Button onClick={handleLoad} className="filter mx-2">
                  <span className="me-2">
                    <IoFilter />
                  </span>
                  Undo Filter
                </Button>
              </span>
            </Col>
            <Col lg="12" md="12" xs="12" className="py-4">
              <div>Magazine Stock Report</div>
            </Col>

            <Col lg="3" md="6" xs="12" className="py-4">
              <DropDownUI
                optionlist={factoryData.map((item) => ({
                  label: item.factory_name,
                  value: item.factory_name,
                }))}
                placeholder="FireWorks Name"
                labelname="FireWorksName"
                name="fireworkname"
                value={formData.fireworkname}
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    fireworkname: updatedFormData.fireworkname,
                  })
                }
              />
            </Col>
            <Col lg="3" md="6" xs="12" className="py-4">
              <DropDownUI
                optionlist={productData.map((item) => ({
                  label: item.product_name,
                  value: item.product_name,
                }))}
                placeholder="Products Name"
                labelname="Products Name"
                name="productsName"
                value={formData.productsName}
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    productsName: updatedFormData.productsName,
                  })
                }
              />
            </Col>
            <Col lg="3" md="6" xs="12" className="py-4">
              <div>
                <Calender
                  setLabel={(date) => setLabel(date, "fromdate")}
                  selectedDate={formData.data}
                  onSelectDate={setDateOfBirth}
                  calenderlabel="From Date"
                />
              </div>
            </Col>
            <Col lg="3" md="6" xs="12" className="py-4">
              <div>
                <Calender
                  setLabel={(date) => setLabel(date, "todate")}
                  selectedDate={formData.data}
                  onSelectDate={setDateOfBirth}
                  calenderlabel="To Date"
                />
              </div>
            </Col>
            <Col lg="6" className="py-4">
              <div>
                {showExcelButton && (
                  <span className="mx-1">
                    {" "}
                    <ClickButton
                      onClick={generateCSV}
                      label={<>Excel</>}
                    ></ClickButton>
                  </span>
                )}
                {csvData.length > 0 && (
                  <CSVLink data={csvData} filename="magazine_stock.csv">
                    <span className="mx-1">
                      <ClickButton
                        onClick={refershdata}
                        label={<>Download</>}
                      ></ClickButton>
                    </span>
                  </CSVLink>
                )}
              </div>
            </Col>

            <Col lg="6" className="py-4">
              <div className="text-end">
                <span className="mx-1">
                  {" "}
                  <ClickButton label={<>PDF</>} onClick={generatePDF}>
                    {" "}
                    <span className="me-2">
                      <IoFilter />
                    </span>
                  </ClickButton>{" "}
                </span>
              </div>
            </Col>

            <Col lg="12" className="py-4">
              <div className="wire-table">
                <Table id="table-container">
                  <thead>
                    <tr>
                      <th rowSpan={2} className="text-center">
                        S No
                      </th>
                      <th rowSpan={2} className="text-center">
                        Product
                      </th>
                      <th colSpan={2} className="text-center">
                        In
                      </th>
                      <th colSpan={2} className="text-center">
                        Out
                      </th>
                      <th colSpan={2} className="text-center">
                        Balance
                      </th>
                    </tr>
                    <tr>
                      <td className="text-center">Unit</td>
                      <td className="text-center">Sub Unit</td>
                      <td className="text-center">Unit</td>
                      <td className="text-center">Sub Unit</td>
                      <td className="text-center">Unit</td>
                      <td className="text-center">Sub Unit</td>
                    </tr>
                  </thead>
                  <tbody>
                    {aggregatedList.map((product, index) => (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td>{product.productName}</td>
                        {/* "In" Columns */}
                        <td className="text-center">
                          {product.inUnitCount
                            ? `${product.inUnitCount.toFixed(2)} ${
                                product.unitType
                              }`
                            : "-"}
                        </td>
                        <td className="text-center">
                          {product.inSubUnitCount
                            ? `${product.inSubUnitCount.toFixed(2)} ${
                                product.subUnitType
                              }`
                            : "-"}
                        </td>
                        {/* "Out" Columns */}
                        <td className="text-center">
                          {product.outUnitCount
                            ? `${product.outUnitCount.toFixed(2)} ${
                                product.unitType
                              }`
                            : "-"}
                        </td>
                        <td className="text-center">
                          {product.outSubUnitCount
                            ? `${product.outSubUnitCount.toFixed(2)} ${
                                product.subUnitType
                              }`
                            : "-"}
                        </td>
                        {/* "Balance" Columns */}
                        <td className="text-center">
                          {product.unitType
                            ? `${product.caseCount.toFixed(2)} ${
                                product.unitType
                              }`
                            : "-"}
                        </td>
                        <td className="text-center">
                          {product.subUnitType
                            ? `${product.caseCount.toFixed(2)} ${
                                product.subUnitType
                              }`
                            : "-"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2} className="text-end fw-bold">
                        Total
                      </td>
                      <td className="text-center fw-bold">
                        {aggregatedList
                          .reduce(
                            (total, product) =>
                              total + (product.inUnitCount || 0),
                            0
                          )
                          .toFixed(2)}
                      </td>
                      <td className="text-center fw-bold">
                        {aggregatedList
                          .reduce(
                            (total, product) =>
                              total + (product.inSubUnitCount || 0),
                            0
                          )
                          .toFixed(2)}
                      </td>
                      <td className="text-center fw-bold">
                        {aggregatedList
                          .reduce(
                            (total, product) =>
                              total + (product.outUnitCount || 0),
                            0
                          )
                          .toFixed(2)}
                      </td>
                      <td className="text-center fw-bold">
                        {aggregatedList
                          .reduce(
                            (total, product) =>
                              total + (product.outSubUnitCount || 0),
                            0
                          )
                          .toFixed(2)}
                      </td>
                      <td className="text-center fw-bold">
                        {aggregatedList
                          .reduce(
                            (total, product) =>
                              total + (product.caseCount || 0),
                            0
                          )
                          .toFixed(2)}
                      </td>
                      <td className="text-center fw-bold">
                        {aggregatedList
                          .reduce(
                            (total, product) =>
                              total + (product.caseCount || 0),
                            0
                          )
                          .toFixed(2)}
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default MagazineStock;
