import React, { useState, useEffect } from "react";
import { Container, Col, Row, Form } from "react-bootstrap";
import API_DOMAIN from "../config/config";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../components/Table";
import { TextInputForm } from "../components/Forms";
import Pagnation from "../components/Pagnation";
import { ClickButton } from "../components/ClickButton";
import { useNavigate } from "react-router-dom";
import MobileView from "../components/MobileView";

const LocationTablehead = [
  "No",
  "Date",
  "Presents",
  "Absents",
  "Total Wages",
  "Action",
];

const Attendance = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  console.log("attendanceData", attendanceData);

  useEffect(() => {
    fetchData();
  }, [searchText, currentPage]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/attendance.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "listAttendance",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        console.log(responseData);
        const transformedData = responseData.body.attendance.map(
          (attendance, index) => {
            const presents = attendance.data.filter(
              (item) => item.status === "present"
            ).length;
            const absents = attendance.data.filter(
              (item) => item.status === "absent"
            ).length;
            const totalWages = attendance.data
              .filter((item) => item.status === "present")
              .reduce((sum, item) => sum + item.wages, 0);
            const formattedDate = formatDate(attendance.entry_date);
            const profiledata = attendance.data;

            return {
              attendance_id: attendance.attendance_id,
              key: (index + 1).toString(),
              entry_date: formattedDate,
              presents: presents,
              absents: absents,
              total_wages: totalWages,
              data: profiledata,
            };
          }
        );

        // Filter the data based on the searchText
        const filteredData = transformedData.filter((item) =>
          item.entry_date.toLowerCase().includes(searchText.toLowerCase())
        );

        setAttendanceData(filteredData); // Update the state with filtered data
      } else {
        throw new Error(responseData.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  const handleSearch = (value) => {
    setSearchText(value.trim()); // Search in DD/MM/YY format
    setCurrentPage(1); // Reset to first page
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col lg="7" md="4" xs="6">
            <div className="page-nav py-3">
              <span className="nav-list"> Attendance</span>
            </div>
          </Col>
          <Col lg="5" md="3" xs="6" className="align-self-center text-end">
            <ClickButton
              label={<>Add New</>}
              onClick={() => navigate("/console/attendance/create")}
            />
          </Col>
          <Col lg="3" md="5" xs="12" className="py-1">
            <Form className="form page-nav">
              <TextInputForm
                placeholder={"Date"}
                onChange={(e) => handleSearch(e.target.value)}
                prefix_icon={<FaMagnifyingGlass />}
                labelname={"Search"}
              ></TextInputForm>
            </Form>
          </Col>
          <Col lg={9} md={12} xs={12} className="py-2">
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={attendanceData.length}
              onPageChange={handlePageChange}
            />
          </Col>
          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-1">
              <TableUI
                headers={LocationTablehead}
                body={attendanceData} // Pass transformed data to TableUI
                type={"attendance"}
                style={{ borderRadius: "5px" }}
              />
              {/* <MobileView
                sno={"01"}
                FactoryName={"Anand Fireworks"}
                Place={"Vettrilaiyurani"}
              /> */}
            </div>
          </Col>

          <Col lg={12} md={12} xs={12}>
            <Pagnation
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={attendanceData.length}
              onPageChange={handlePageChange}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Attendance;
