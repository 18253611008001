import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageNav from "../components/PageNav";
import { TextInputForm } from "../components/Forms";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import API_DOMAIN from "../config/config";
import { ClickButton } from "../components/ClickButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";

const StaffCreation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { type, rowData } = location.state || {};

  // Set initial form state depending on 'type' (edit, view, creation)
  const initialState =
    type === "edit"
      ? { ...rowData }
      : { staff_name: "", mobile_number: "", role: "", place: "" };

  const [formData, setFormData] = useState(initialState);

  // Handle input changes
  const handleInputChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  // Handle form submission for staff creation
  const handleSubmit = async () => {
    try {
      for (const key in formData) {
        if (formData[key] === "") {
          toast.error(`${key} cannot be empty!`, {
            position: "top-center",
            autoClose: 2000,
            theme: "colored",
          });
          return; // Exit if any field is empty
        }
      }

      setLoading(true); // Show loading spinner
      const response = await fetch(`${API_DOMAIN}/staff.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          staff_name: formData.staff_name,
          mobile_number: formData.mobile_number,
          role: formData.role,
          place: formData.place,
          action: "createStaff", // Action for creating staff
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const textResponse = await response.text();
      if (textResponse) {
        const responseData = JSON.parse(textResponse);
        console.log("responseData", responseData);
        if (responseData.status === 200) {
          toast.success("🦄 Staff successfully added!", {
            position: "top-center",
            autoClose: 2000,
            theme: "colored",
          });
          setTimeout(() => {
            navigate("/console/master/staff");
          }, 2000);
        } else {
          toast.error(responseData?.head?.msg || "Failed to add staff!", {
            position: "top-center",
            autoClose: 2000,
            theme: "colored",
          });
        }
      } else {
        throw new Error("Empty response from server.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-center",
        autoClose: 2000,
        theme: "colored",
      });
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  // Handle form submission for staff update
  const handleUpdateSubmit = async () => {
    setLoading(true); // Show loading spinner
    try {
      const response = await fetch(`${API_DOMAIN}/staff.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          edit_staff_id: rowData.id, // Include the staff ID for update
          staff_name: formData.staff_name,
          mobile_number: formData.mobile_number,
          role: formData.role,
          place: formData.place,
          action: "updateStaff", // Action for updating staff
        }),
      });

      // Parse the response body
      const responseData = await response.json();

      // Handle response based on status code
      if (responseData?.status === 200) {
        toast.success("Staff updated successfully!", {
          position: "top-center",
          autoClose: 2000,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/master/staff");
        }, 2000);
      } else {
        toast.error(responseData?.head?.msg || "Failed to update staff!", {
          position: "top-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-center",
        autoClose: 2000,
        theme: "colored",
      });
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  return (
    <div className="main-content">
      <Container>
        <Row className="regular justify-content-center">
          <Col lg="12" md="12" xs="12" className="py-3 d-inline">
            <PageNav
              pagetitle={`Staff ${
                type === "view"
                  ? "View"
                  : type === "edit"
                  ? "Update"
                  : "Creation"
              }`}
            />
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            <TextInputForm
              placeholder="Staff Name"
              labelname="Staff Name"
              value={formData.staff_name}
              onChange={(e) => handleInputChange(e, "staff_name")}
              disabled={type === "view"} // Disable fields for view type
            />
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            <TextInputForm
              placeholder="Mobile Number"
              labelname="Mobile Number"
              value={formData.mobile_number}
              onChange={(e) => handleInputChange(e, "mobile_number")}
              disabled={type === "view"}
            />
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            <TextInputForm
              placeholder="Role"
              labelname="Role"
              value={formData.role}
              onChange={(e) => handleInputChange(e, "role")}
              disabled={type === "view"}
            />
          </Col>
          <Col lg="4" md="4" xs="12" className="py-3">
            <TextInputForm
              placeholder="Place"
              labelname="Place"
              value={formData.place}
              onChange={(e) => handleInputChange(e, "place")}
              disabled={type === "view"}
            />
          </Col>
          <Col lg="12" md="12" xs="12" className="py-5 align-self-center">
            <div className="text-center">
              {type === "view" ? (
                <ClickButton
                  label="Back"
                  onClick={() => navigate("/console/master/staff")}
                />
              ) : (
                <>
                  {type === "edit" ? (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Update </>}
                          onClick={() => handleUpdateSubmit()}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/master/staff")}
                        ></ClickButton>
                      </span>
                    </>
                  ) : (
                    <>
                      {/* Always show ToastContainer */}
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />

                      {loading ? (
                        <center>
                          {/* Show Spinner while loading */}
                          <Spinner animation="border" variant="dark" />
                        </center>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          <span className="mx-2">
                            <ClickButton
                              label={<>Submit</>}
                              onClick={handleSubmit}
                              disabled={loading} // Disable button during loading
                            ></ClickButton>
                          </span>
                          <span className="mx-2">
                            <ClickButton
                              label={<>Cancel</>}
                              onClick={() => navigate("/console/master/staff")}
                            ></ClickButton>
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default StaffCreation;
