import React, { useState, useEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import PageNav from "../components/PageNav";
import API_DOMAIN from "../config/config";
import { BsStopCircleFill } from "react-icons/bs";
import { TextInputForm, Calender } from "../components/Forms";
import { ClickButton } from "../components/ClickButton";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const AttendanceCreation = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [attendanceData, setAttendanceData] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const today = new Date();
  const defaultDate = today.toISOString().substr(0, 10);
  const location = useLocation(); // Use the useLocation hook to get the location object

  const { type, rowData } = location.state || {}; // Access location.state safely
  const parseDate = (dateString) => {
    if (!dateString) return new Date(); // Default to today if no date is provided
    const parts = dateString.split("/"); // Split the date string by '/'
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return new Date(`20${year}-${month}-${day}`); // Convert to ISO format
    }
    return new Date(dateString); // Fallback for other valid formats
  };

  const initialFormData =
    type === "edit"
      ? {
          ...rowData,
          date:
            rowData.entry_date &&
            parseDate(rowData.entry_date).toISOString().split("T")[0], // Convert date
        }
      : {
          date: defaultDate,
          data: [],
        };

  const [formData, setFormData] = useState(initialFormData);

  console.log("formData", formData);
  console.log("initialFormData", initialFormData);
  useEffect(() => {
    // Check if "edit" mode and rowData is available
    if (type === "edit" && rowData) {
      const { entry_date, data } = rowData;

      // Update formData with existing attendance record
      setFormData({
        date: entry_date, // Pre-fill the date
        data: data, // Set staff data
        attendance_id: rowData.attendance_id, // Add attendance_id
      });

      // Transform data into attendanceData for easier form manipulation
      const initialAttendanceData = data.reduce((acc, staff) => {
        acc[staff.staff_name] = {
          status: staff.status, // Present or Absent
          wages: staff.wages, // Wages value
        };
        return acc;
      }, {});

      setAttendanceData(initialAttendanceData); // Set initial attendance state
    }
  }, [type, rowData]);

  // Fetch staff data
  const fetchData = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/staff.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ action: "listStaff" }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      if (responseData.head.code === 200) {
        setUserData(responseData.body.staff);
      } else {
        throw new Error(responseData.head.msg || "Unknown error");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAttendanceChange = (staffName, type) => {
    setAttendanceData((prev) => ({
      ...prev,
      [staffName]: {
        ...prev[staffName],
        status: type,
      },
    }));
  };

  const handleWagesChange = (staffName, value) => {
    setAttendanceData((prev) => ({
      ...prev,
      [staffName]: {
        ...prev[staffName],
        wages: value,
      },
    }));
  };

  const handleSubmit = async () => {
    setLoading(true); // Show the spinner when the request starts

    try {
      // Ensure selectedDate is either set or default to today's date
      const dateToSubmit = selectedDate || defaultDate;

      // Preparing the final data in the correct format
      const finalData = {
        date: dateToSubmit, // Add the selected date here
        data: userData.map((staff) => ({
          staff_name: staff.staff_name,
          status: attendanceData[staff.staff_name]?.status || "absent",
          wages: attendanceData[staff.staff_name]?.wages || 0,
        })),
      };

      console.log(finalData);

      const response = await fetch(`${API_DOMAIN}/attendance.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "createAttendance",
          ...finalData, // This ensures the 'data' and 'date' key are included in the request
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to submit attendance");
      }

      const responseData = await response.json();
      console.log("responseData", responseData);
      if (responseData.head.code === 200) {
        toast.success("Attendance submitted successfully!");
      } else {
        throw new Error(responseData.head.msg || "Unknown error");
      }
      setTimeout(() => {
        navigate("/console/attendance");
      }, 2000);
    } catch (error) {
      console.error("Error submitting attendance:", error.message);
    } finally {
      setLoading(false); // Hide the spinner after the request completes
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const updatedData = formData.data.map((staff) => ({
        staff_name: staff.staff_name,
        status: attendanceData[staff.staff_name]?.status || "absent",
        wages: attendanceData[staff.staff_name]?.wages || 0,
      }));

      const payload = {
        attendance_id: formData.attendance_id, // Required for update
        date: formData.date, // Include the date
        data: updatedData, // Updated staff data
      };

      const response = await fetch(`${API_DOMAIN}/attendance.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "updateAttendance",
          ...payload,
        }),
      });

      const result = await response.json();
      if (result.head.code === 200) {
        toast.success("Attendance updated successfully!");
      } else {
        throw new Error(result.head.msg || "Failed to update attendance.");
      }
    } catch (error) {
      console.error("Update Error:", error.message);
      toast.error("Failed to update attendance.");
    }
  };

  const getStatusColor = (name) => {
    const status = attendanceData[name]?.status;
    return status === "present"
      ? "green"
      : status === "absent"
      ? "red"
      : "gray";
  };

  const handleChange = (e, fieldName, rowIndex) => {
    const value = e.target ? e.target.value : e.value;

    let updatedFormData = { ...formData };

    if (rowIndex !== undefined) {
      updatedFormData = {
        ...formData,
      };
    } else {
      updatedFormData = {
        ...formData,
        [fieldName]: value,
      };
    }

    setFormData({
      updatedFormData,
    });
  };

  const setLabel = (date, label) => {
    const formattedDate =
      date instanceof Date
        ? date.toISOString().split("T")[0] // Format as "YYYY-MM-DD"
        : date;

    setFormData((prevData) => ({
      ...prevData,
      [label]: formattedDate,
    }));
  };

  return (
    <div className="main-content">
      <ToastContainer />
      <Container>
        <Row className="regular">
          <Col lg="6" md="12" xs="12" className="py-3">
            <PageNav
              pagetitle={`attendance${
                type === "view"
                  ? " view"
                  : type === "edit"
                  ? "  Update"
                  : "Creation"
              }`}
            />
          </Col>
          <Col lg="6" className="py-3 text-end">
            {/* <ClickButton label="Print" /> */}
          </Col>
          <Col lg="4" md="4" xs="4" className="py-3">
            <Calender
              setLabel={(date) => setSelectedDate(date, "date")}
              calenderlabel="Date"
              initialDate={type === "edit" ? formData.date : undefined}
              disabled={type === "edit"}
            />
          </Col>

          <Col xs="12">
            <Table>
              <thead>
                <tr>
                  <th className="w-75">Staff Name</th>
                  <th>Present</th>
                  <th>Absent</th>
                  <th>Wages</th>
                </tr>
              </thead>
              <tbody>
                {userData.map((staff, index) => (
                  <tr key={index}>
                    <td>{staff.staff_name}</td>
                    <td>
                      <BsStopCircleFill
                        size={32}
                        onClick={() =>
                          handleAttendanceChange(staff.staff_name, "present")
                        }
                        style={{
                          color:
                            getStatusColor(staff.staff_name) === "green"
                              ? "green"
                              : "gray",
                          cursor: "pointer",
                        }}
                      />
                    </td>
                    <td>
                      <BsStopCircleFill
                        size={32}
                        onClick={() =>
                          handleAttendanceChange(staff.staff_name, "absent")
                        }
                        style={{
                          color:
                            getStatusColor(staff.staff_name) === "red"
                              ? "red"
                              : "gray",
                          cursor: "pointer",
                        }}
                      />
                    </td>
                    <td>
                      <TextInputForm
                        value={attendanceData[staff.staff_name]?.wages || ""}
                        onChange={(e) =>
                          handleWagesChange(staff.staff_name, e.target.value)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col lg="12" md="12" xs="12" className="py-5 align-self-center">
            <div className="text-center">
              {type === "view" ? (
                <ClickButton
                  label="Back"
                  onClick={() => navigate("/console/attendance")}
                />
              ) : (
                <>
                  {type === "edit" ? (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Update </>}
                          onClick={() => handleUpdateSubmit()}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/attendance")}
                        ></ClickButton>
                      </span>
                    </>
                  ) : (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />

                      {loading ? (
                        <center>
                          {/* Show Spinner while loading */}
                          <Spinner animation="border" variant="dark" />
                        </center>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          <span className="mx-2">
                            <ClickButton
                              label={<>Submit</>}
                              onClick={handleSubmit}
                              disabled={loading} // Disable the submit button while loading
                            ></ClickButton>
                          </span>
                          <span className="mx-2">
                            <ClickButton
                              label={<>Cancel</>}
                              onClick={() => navigate("/console/attendance")}
                            ></ClickButton>
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AttendanceCreation;
