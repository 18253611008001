import React, { useState, useEffect } from "react";
import { Container, Col, Row, Table } from "react-bootstrap";
import { ClickButton } from "../components/ClickButton";
import { Calender, DropDownUI } from "../components/Forms";
import { IoFilter } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import API_DOMAIN from "../config/config";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const logoImage = require("../components/sidebar/images/logo.jpeg");

const ContractReport = () => {
  const initialFormData = {
    contractorName: "",
    productsName: "",
    fromdate: "",
    todate: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });
  const [productData, setProductData] = useState([]);
  const [contractorData, setContractorData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stockData, setStockData] = useState([]);

  const setLabel = (value, field) => {
    const formattedDate = new Date(value).toISOString().split("T")[0]; // Ensure YYYY-MM-DD
    setFormData((prevData) => ({
      ...prevData,
      [field]: formattedDate,
    }));
    fetchProductData(); // Fetch filtered data after setting the date
  };

  const fetchProductData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/payroll.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "listPayrolls",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const responseData = await response.json();

      if (responseData.head.code === 200) {
        let sortedData = responseData.body.payrolls;

        // Filter by contractor name
        if (formData.contractorName) {
          sortedData = sortedData.filter(
            (user) =>
              user.contractor_name &&
              user.contractor_name.toLowerCase() ===
              formData.contractorName.toLowerCase()
          );
        }

        // Filter by product name
        if (formData.productsName) {
          sortedData = sortedData.filter((user) => {
            const productData = JSON.parse(user.products || "[]");
            return productData.some((product) =>
              product.productName
                ? product.productName.toLowerCase() ===
                formData.productsName.toLowerCase()
                : false
            );
          });
        }

        // Filter by date range (from and to dates)
        const fromDate = formData.fromdate ? new Date(formData.fromdate) : null;
        const toDate = formData.todate ? new Date(formData.todate) : null;

        sortedData = sortedData.filter((user) => {
          const createDate = new Date(user.from_date); // Ensure proper date parsing
          const isAfterFromDate = fromDate ? createDate >= fromDate : true;
          const isBeforeToDate = toDate ? createDate <= toDate : true;
          return isAfterFromDate && isBeforeToDate;
        });

        // Parse product data and calculate totals
        const parsedData = sortedData
          .map((payroll) => {
            const products = JSON.parse(payroll.products || "[]");
            return products.map((product) => {
              const totalQuantity =
                product.caseCount * (product.subUnitType || 1);
              const totalAmount = totalQuantity * product.coolyRate;
              return {
                ...product,
                totalQuantity,
                totalAmount,
                contractor_name: payroll.contractor_name,
                from_date: payroll.from_date,
              };
            });
          })
          .flat(); // Flatten the array of products
        console.log("payroll data", parsedData);
        setStockData(parsedData);
      } else {
        throw new Error(responseData.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  const fetchProductDataList = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/product.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          search_text: "",
          action: "listProduct",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      if (responseData.head.code === 200) {
        let sortedData = responseData.body.products;
        console.log("product", sortedData);
        setProductData(sortedData);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  const fetchContractorData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/contractor.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          action: "listContractors",
        }),
      });
      setLoading(false);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      if (responseData.status === 200 && responseData.message === "Success") {
        let sortedData = responseData.data;
        console.log(sortedData);
        setContractorData(sortedData);
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductDataList();
    fetchProductData();
    fetchContractorData();
  }, [formData]);

  const handleLoad = () => {
    fetchProductData();
    setFormData({
      contractorName: "",
      productsName: "",
      fromdate: "",
      todate: "",
    });
  };

  const formatDate = (date) => {
    const d = new Date(date);
    return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
  };

  const getWeekDates = () => {
    if (formData.fromdate && formData.todate) {
      const startDate = new Date(formData.fromdate);
      const endDate = new Date(formData.todate);
      const weekDates = [];
      for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
        weekDates.push(formatDate(new Date(d)));
      }
      return weekDates;
    }
    const today = new Date();
    const startOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay())
    );
    const endOfWeek = new Date(today.setDate(today.getDate() + 6));

    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startOfWeek);
      date.setDate(startOfWeek.getDate() + i);
      const formattedDate = formatDate(date);
      weekDates.push(formattedDate);
    }

    return weekDates;
  };

  const weekDates = getWeekDates();

  function generatePDF() {
    const input = document.getElementById("table-container");

    html2canvas(input, {
      logging: true,
      useCORS: true,
      imageTimeout: 15000,
      allowTaint: true,
    })
      .then((canvas) => {
        const pdf = new jsPDF("l", "in", "a4");

        const img = new Image();
        img.src = logoImage; // Replace `logoImage` with your image URL or base64 string

        img.onload = function () {
          // Add image above title
          const imgWidth = 1.5; // Image width in inches
          const imgHeight = (imgWidth / img.width) * img.height; // Maintain aspect ratio
          const imgX = (pdf.internal.pageSize.width - imgWidth) / 2; // Center the image
          const imgY = 0.1; // Top margin
          pdf.addImage(img, "PNG", imgX, imgY, imgWidth, imgHeight);

          // Add title below the image
          let title = "Maha Vilvam Pyropark \n Contractor Report";
          if (
            formData.contractorName ||
            formData.productsName ||
            formData.fromdate ||
            formData.todate
          ) {
            title += "\n(";
            const extraTitles = [];
            if (formData.contractorName)
              extraTitles.push(`Contractor name: ${formData.contractorName}`);
            if (formData.productsName)
              extraTitles.push(`Products Name: ${formData.productsName}`);
            if (formData.fromdate)
              extraTitles.push(`From Date: ${formData.fromdate}`);
            if (formData.todate)
              extraTitles.push(`To Date: ${formData.todate}`);
            title += extraTitles.join(", ") + ")\n";
          }

          const titleX = pdf.internal.pageSize.width / 2;
          const titleY = imgY + imgHeight + 0.3; // Position below the image
          pdf.setFontSize(14);
          pdf.setFont("helvetica", "bold");
          pdf.text(titleX, titleY, title, null, null, "center");

          // Dynamically adjust the width and height of the canvas image
          const tableImageWidth =
            canvas.width > 1400 ? 10 : (canvas.width / canvas.height) * 10;
          const tableImageHeight =
            tableImageWidth * (canvas.height / canvas.width);

          // Add table content below the title
          pdf.addImage(
            canvas.toDataURL("image/jpeg", 2),
            "JPEG",
            0.7, // Left margin
            titleY + 0.7, // Below the title
            tableImageWidth,
            tableImageHeight
          );

          // Save the PDF
          pdf.save("table.pdf");
        };

        img.onerror = function () {
          console.error("Failed to load the company logo.");
        };
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  }
  console.log("stockData", stockData);
  const aggregatedData = stockData.reduce((acc, item) => {
    const contractorKey = item.contractor_name;

    // Initialize contractor data if not already done
    if (!acc[contractorKey]) {
      acc[contractorKey] = {
        contractor_name: item.contractor_name,
        products: {},
        contractorTotalAmount: 0, // Initialize total amount for contractor
      };
    }

    // Initialize product data if not already done
    if (!acc[contractorKey].products[item.productName]) {
      acc[contractorKey].products[item.productName] = {};
    }

    // Get product data for sub_count conversion
    const matchedProduct = productData.find(
      (p) => p.product_name === item.productName
    );
    const subCount = matchedProduct?.Sub_count || 1; // Default subCount to 1

    const isCase = item.unitType;
    const isPcs = item.subUnitType;

    const convertedCaseCount = parseFloat(item.caseCount);

    // Format the creation date (YYYY-MM-DD)
    const productKey = formatDate(item.from_date);

    // Initialize data for this product and date if not already done
    if (!acc[contractorKey].products[item.productName][productKey]) {
      acc[contractorKey].products[item.productName][productKey] = {
        caseCount: 0,
        total: 0,
        perRate: 0,
        unitType: item.unitType,
      };
    }

    // Aggregate caseCount and totals
    acc[contractorKey].products[item.productName][productKey].caseCount +=
      convertedCaseCount;
    acc[contractorKey].products[item.productName][productKey].total +=
      item.total;

    // Calculate the perRate
    const perRate = isCase
      ? Number(item.coolyRate)
      : isPcs
        ? Number(item.coolyRate) / subCount
        : 0;

    acc[contractorKey].products[item.productName][productKey].perRate = perRate;

    // Aggregate contractor total amount
    acc[contractorKey].contractorTotalAmount += item.total;

    return acc;
  }, {});

  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col lg="12" className="py-4 text-end">
            <span>
              <Button onClick={handleLoad} className="filter mx-2">
                <span className="me-2">
                  <IoFilter />
                </span>
                Undo Filter
              </Button>
            </span>
          </Col>
          <Col lg="7" md="4" xs="6" className="align-self-center py-3">
            <div className="page-nav py-3">
              <span className="nav-list">Contract Report</span>
            </div>
          </Col>
          <Col lg="5" className="align-self-center py-3">
            <div className="text-end">
              <ClickButton onClick={generatePDF} label={<>PDF</>}></ClickButton>
            </div>
          </Col>
          <Col lg="3" md="6" xs="12" className="py-4">
            <DropDownUI
              optionlist={contractorData.map((item) => ({
                label: item.contractor_name,
                value: item.contractor_name,
              }))}
              placeholder="Contractor"
              labelname="Contractor"
              name="contractorName"
              value={formData.contractorName}
              onChange={(updatedFormData) =>
                setFormData({
                  ...formData,
                  contractorName: updatedFormData.contractorName,
                })
              }
            />
          </Col>
          <Col lg="3" md="6" xs="12" className="py-4">
            <DropDownUI
              optionlist={productData.map((item) => ({
                label: item.product_name,
                value: item.product_name,
              }))}
              placeholder="Products Name"
              labelname="Products Name"
              name="productsName"
              value={formData.productsName}
              onChange={(updatedFormData) =>
                setFormData({
                  ...formData,
                  productsName: updatedFormData.productsName,
                })
              }
            />
          </Col>
          <Col lg="3" md="6" xs="12" className="py-4">
            <div>
              <Calender
                setLabel={(date) => setLabel(date, "fromdate")}
                selectedDate={formData.fromdate}
                calenderlabel="From Date"
              />
            </div>
          </Col>
          <Col lg="3" md="6" xs="12" className="py-4">
            <div>
              <Calender
                setLabel={(date) => setLabel(date, "todate")}
                selectedDate={formData.todate}
                calenderlabel="To Date"
              />
            </div>
          </Col>
          <Col lg="12">
            <div className="report wire-table">
              <Table className="w-100" id="table-container">
                <thead>
                  <tr className="fw-bold">
                    <th>Contractor</th>
                    <th>Product</th>
                    {weekDates.map((day, index) => (
                      <th key={index} style={{ width: "100px" }}>
                        {day}
                      </th>
                    ))}
                    <th style={{ width: "132px" }}>Total Qty (Unit)</th>
                    <th>Rate</th>
                    <th>Total Rate</th>
                    <th style={{ width: "132px" }}>Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(aggregatedData).map(
                    (contractor, contractorIdx) => (
                      <React.Fragment key={contractorIdx}>
                        {Object.entries(contractor.products).map(
                          ([productName, dailyData], productIdx) => (
                            <tr key={productIdx}>
                              {productIdx === 0 && (
                                <td
                                  rowSpan={
                                    Object.keys(contractor.products).length
                                  }
                                >
                                  {contractor.contractor_name}
                                </td>
                              )}
                              <td>{productName}</td>
                              {weekDates.map((day, dayIdx) => {
                                const dailyRecord = dailyData[day] || {
                                  caseCount: 0,
                                  total: 0,
                                  perRate: 0,
                                };
                                return (
                                  <td key={dayIdx}>
                                    {dailyRecord.caseCount || "-"}
                                  </td>
                                );
                              })}
                              <td>
                                {Object.values(dailyData).reduce(
                                  (sum, record) => sum + (parseFloat(record.caseCount) || 0),
                                  0
                                )} ({Object.values(dailyData)[0]?.unitType || "Unknown"})
                              </td>
                              <td>
                                {Object.values(dailyData)[0] &&
                                  typeof Object.values(dailyData)[0].perRate ===
                                  "number"
                                  ? Object.values(dailyData)[0].perRate.toFixed(
                                    2
                                  )
                                  : "-"}
                              </td>
                              <td>
                                {Object.values(dailyData)[0] &&
                                  typeof Object.values(dailyData)[0].perRate ===
                                  "number"
                                  ? (Object.values(dailyData).reduce(
                                    (sum, record) =>
                                      sum + (parseFloat(record.caseCount) || 0),
                                    0
                                  ) * Object.values(dailyData)[0].perRate.toFixed(
                                    2
                                  ))
                                  : "-"}
                              </td>
                              {productIdx === 0 && (
                                <td
                                  rowSpan={
                                    Object.keys(contractor.products).length
                                  }
                                >
                                  {contractor.contractorTotalAmount &&
                                    !isNaN(contractor.contractorTotalAmount)
                                    ? contractor.contractorTotalAmount.toFixed(
                                      2
                                    )
                                    : "-"}
                                </td>
                              )}
                            </tr>
                          )
                        )}
                      </React.Fragment>
                    )
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      colSpan={weekDates.length + 5}
                      className="text-end fw-bold"
                    >
                      Total Amount
                    </td>
                    <td className="fw-bold">
                      {Object.values(aggregatedData)
                        .reduce(
                          (totalSum, contractor) =>
                            totalSum + (contractor.contractorTotalAmount || 0),
                          0
                        )
                        .toFixed(2)}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContractReport;
