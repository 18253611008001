import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { ClickButton } from "../components/ClickButton";
import { Calender } from "../components/Forms";
import jsPDF from "jspdf";
import API_DOMAIN from "../config/config";
import html2canvas from "html2canvas";
const logoImage = require("../components/sidebar/images/logo.jpeg");

const AttendanceReport = ({ attendanceData: initialAttendanceData }) => {
  const initialFormData = {
    customer_name: "",
    product_name: "",
    fromdate: "",
    todate: "",
  };

  const [attendanceData, setAttendanceData] = useState(
    initialAttendanceData || []
  );
  const [loading, setLoading] = useState(false);
  const [stockData, setStockData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [formData, setFormData] = useState({ ...initialFormData });
  const [productData, setProductData] = useState([]);
  const [staffSummary, setStaffSummary] = useState([]);

  console.log("stockData", stockData);
  console.log(productData);
  console.log(formData);

  const setDateOfBirth = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      date: date,
    }));
  };

  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  useEffect(() => {
    // Extract and calculate staff data
    const staffDetails = {};

    attendanceData.forEach((attendance) => {
      attendance.data.forEach((record) => {
        const { staff_name, status, wages } = record;

        if (!staffDetails[staff_name]) {
          staffDetails[staff_name] = {
            totalDays: 0,
            presentDays: 0,
            absentDays: 0,
            totalSalary: 0,
          };
        }

        // Increment total days
        staffDetails[staff_name].totalDays += 1;

        if (status === "present") {
          staffDetails[staff_name].presentDays += 1;
          staffDetails[staff_name].totalSalary += wages;
        } else if (status === "absent") {
          staffDetails[staff_name].absentDays += 1;
        }
      });
    });

    const summaryArray = Object.keys(staffDetails).map((staff) => ({
      staffName: staff,
      ...staffDetails[staff],
    }));

    setStaffSummary(summaryArray);
  }, [attendanceData]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_DOMAIN}/attendance.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "listAttendance",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();

      if (responseData.head.code === 200) {
        const transformedData = responseData.body.attendance.map(
          (attendance, index) => {
            const presents = attendance.data.filter(
              (item) => item.status === "present"
            ).length;
            const absents = attendance.data.filter(
              (item) => item.status === "absent"
            ).length;
            const totalWages = attendance.data
              .filter((item) => item.status === "present")
              .reduce((sum, item) => sum + item.wages, 0);

            return {
              attendance_id: attendance.attendance_id,
              key: (index + 1).toString(),
              entry_date: attendance.entry_date, // Use raw date here
              presents: presents,
              absents: absents,
              total_wages: totalWages,
              data: attendance.data,
            };
          }
        );

        // Filter based on fromdate and todate
        const filteredData = transformedData.filter((item) => {
          const entryDate = new Date(item.entry_date); // Convert to Date object
          const fromDate = formData.fromdate
            ? new Date(formData.fromdate)
            : null;
          const toDate = formData.todate ? new Date(formData.todate) : null;

          // Debugging logs
          console.log("entryDate:", entryDate);
          console.log("fromDate:", fromDate);
          console.log("toDate:", toDate);

          return (
            (!fromDate || entryDate >= fromDate) &&
            (!toDate || entryDate <= toDate)
          );
        });

        setAttendanceData(filteredData);
      } else {
        throw new Error(responseData.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [formData]);

  const handleLoad = () => {
    setFormData(initialFormData);
  };

  const generatePDF = () => {
    const input = document.getElementById("table-container");
    html2canvas(input, {
      width: 1400,
      height: 1200,
      scale: 5,
      useCORS: true,
    })
      .then((canvas) => {
        const pdf = new jsPDF("p", "in", "letter");
        const img = new Image();
        img.onload = function () {
          const imgWidth = 2;
          const imgHeight = (imgWidth / this.width) * this.height;
          const logoX = (pdf.internal.pageSize.width - imgWidth) / 2;
          const logoY = 0.1;
          pdf.addImage(img, "PNG", logoX, logoY, imgWidth, imgHeight);

          const title = "Maha Vilvam Pyropark Delivery Report";
          const titleX = pdf.internal.pageSize.width / 2;
          const titleY = 2.5;
          pdf.setFontSize(14);
          pdf.setFont("helvetica", "bold");
          pdf.text(titleX, titleY, title, null, null, "center");

          pdf.addImage(
            canvas.toDataURL("image/jpeg", 2),
            "JPEG",
            0.4,
            3,
            8.2,
            10
          );
          pdf.save("table.pdf");
        };

        img.src = logoImage;
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  return (
    <div className="main-content">
      <Container>
        <Row>
          <Col lg="7" md="4" xs="6" className="align-self-center py-3">
            <div className="page-nav py-3">
              <span className="nav-list">Attendance</span>
            </div>
          </Col>
          <Col lg="5" className="align-self-center py-3">
            <div className="text-end">
              <ClickButton onClick={generatePDF} label={<>PDF</>}></ClickButton>
            </div>
          </Col>
          <Col lg="3" md="6" xs="12" className="py-4">
            <Calender
              setLabel={(date) => setLabel(date, "fromdate")}
              selectedDate={formData.fromdate}
              onSelectDate={setDateOfBirth}
              calenderlabel="From Date"
            />
          </Col>
          <Col lg="3" md="6" xs="12" className="py-4">
            <Calender
              setLabel={(date) => setLabel(date, "todate")}
              selectedDate={formData.todate}
              onSelectDate={setDateOfBirth}
              calenderlabel="To Date"
            />
          </Col>
          <Col lg="12">
            <div className="wire-table">
              <Table id="table-container">
                <thead>
                  <tr className="fw-bold">
                    <th rowSpan={2}>S No</th>
                    <th rowSpan={2}>Staff Name</th>
                    <th rowSpan={2}>Total Days</th>
                    <th rowSpan={2}>Present Days</th>
                    <th rowSpan={2}>Absent Days</th>
                    <th className="text-center">Salary</th>
                  </tr>
                </thead>
                <tbody>
                  {staffSummary.map((staff, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{staff.staffName}</td>
                      <td>{staff.totalDays}</td>
                      <td>{staff.presentDays}</td>
                      <td>{staff.absentDays}</td>
                      <td>{staff.totalSalary}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AttendanceReport;
