import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PageNav from "../components/PageNav";
import { TextInputForm } from "../components/Forms";
import { ClickButton } from "../components/ClickButton";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import API_DOMAIN from "../config/config";
import Spinner from "react-bootstrap/Spinner";
import "react-toastify/dist/ReactToastify.css";
const CustomerCreation = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const navigate = useNavigate();

  const [setShowAlert] = useState(false);

  const initialState =
    type === "edit"
      ? { ...rowData }
      : {
          name: "",
          address: "",
          place: "",
          mobile_number: "",
        };

  const [formData, setFormData] = useState(initialState);

  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    // // Validate form data fields
    // for (const key in formData) {
    //   if (formData[key] === "") {
    //     toast.error(`${key} cannot be empty!`, {
    //       position: "top-center",
    //       autoClose: 2000,
    //       theme: "colored",
    //     });
    //     return; // Exit if any field is empty
    //   }
    // }

    // Validate mobile number
    // const mobileNumber = formData.mobile_number;
    // if (!/^\d{10}$/.test(mobileNumber)) {
    //   toast.error("Mobile number must be a 10-digit number!", {
    //     position: "top-center",
    //     autoClose: 2000,
    //     theme: "colored",
    //   });
    //   return;
    // }

    setLoading(true); // Show loading spinner

    try {
      const response = await fetch(`${API_DOMAIN}/customer.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          action: "createCustomer",
        }),
      });

      // Log the raw response
      console.log("Raw Response:", response);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json(); // This line throws the error
      console.log("Parsed JSON Response:", responseData);

      if (responseData.head.code === 200) {
        toast.success("🦄 Customer Data Successfully Added!", {
          position: "top-center",
          autoClose: 2000,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/master/customer");
        }, 2000);
      } else {
        toast.error(responseData.head.message || "Failed to add customer!", {
          position: "top-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error during submit:", error);

      // Additional logging for HTML response
      if (error.name === "SyntaxError") {
        toast.error("Unexpected server response. Please check API!", {
          position: "top-center",
          autoClose: 2000,
          theme: "colored",
        });
      }
    } finally {
      setLoading(false); // Hide loading spinner after operation is complete
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      const mobileNumber = formData.mobile_number;
      if (!/^\d{10}$/.test(mobileNumber)) {
        toast.error("Mobile number must be a 10-digit number!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return; // Exit the function early if mobile number is invalid
      }
      const response = await fetch(`${API_DOMAIN}/customer.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "updateCustomer",
          edit_Customer_id: rowData.id,
          ...formData,
        }),
      });
      console.log("a", response);
      if (!response.ok) {
        throw new Error("Failed to update customer");
      }

      const responseData = await response.json();
      console.log("b", responseData);

      if (responseData.head.code === 200) {
        toast.success("🦄 Customer updated successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          navigate("/console/master/customer");
        }, 2000);
      } else {
        console.error(
          responseData.head.message || "Unknown error occurred during update"
        );
      }
    } catch (error) {
      console.error("Error updating customer:", error.message);
    }
  };
  return (
    <div className="main-content">
      <Container>
        <Row className="regular">
          <Col lg="12" md="12" xs="12" className="py-3">
            <PageNav
              pagetitle={`Customer${
                type === "view"
                  ? " view"
                  : type === "edit"
                  ? "  Update"
                  : "Creation"
              }`}
            ></PageNav>
          </Col>
          <Col lg="3" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Customer Name"}
                labelname={"Customer Name"}
                value={formData.name}
                onChange={(e) => handleChange(e, "name")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Customer Name"}
                labelname={"Customer Name"}
                value={type === "view" ? rowData.name : formData.name}
                onChange={(e) => handleChange(e, "name")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="3" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Address"}
                labelname={"Address"}
                value={formData.address}
                onChange={(e) => handleChange(e, "address")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Address"}
                labelname={"Address"}
                value={type === "view" ? rowData.address : formData.address}
                onChange={(e) => handleChange(e, "address")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="3" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Place"}
                labelname={"Place"}
                value={formData.place}
                onChange={(e) => handleChange(e, "place")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Place"}
                labelname={"Place"}
                value={type === "view" ? rowData.place : formData.place}
                onChange={(e) => handleChange(e, "place")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="3" md="4" xs="12" className="py-3">
            {type === "edit" ? (
              <TextInputForm
                placeholder={"Mobile No."}
                labelname={"Mobile No."}
                value={formData.mobile_number}
                onChange={(e) => handleChange(e, "mobile_number")}
              ></TextInputForm>
            ) : (
              <TextInputForm
                placeholder={"Mobile No."}
                labelname={"Mobile No."}
                value={
                  type === "view"
                    ? rowData.mobile_number
                    : formData.mobile_number
                }
                onChange={(e) => handleChange(e, "mobile_number")}
              ></TextInputForm>
            )}
          </Col>
          <Col lg="12" md="12" xs="12" className="py-5 align-self-center">
            <div className="text-center">
              {type === "view" ? (
                <ClickButton
                  label={<>back</>}
                  onClick={() => navigate("/console/master/customer")}
                ></ClickButton>
              ) : (
                <>
                  {type === "edit" ? (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />
                      <span className="mx-2">
                        <ClickButton
                          label={<>Update</>}
                          onClick={handleUpdateSubmit}
                        ></ClickButton>
                      </span>
                      <span className="mx-2">
                        <ClickButton
                          label={<>Cancel</>}
                          onClick={() => navigate("/console/master/customer")}
                        ></ClickButton>
                      </span>
                    </>
                  ) : (
                    <>
                      <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                      />

                      {loading ? (
                        <center>
                          {/* Show Spinner while loading */}
                          <Spinner animation="border" variant="dark" />
                        </center>
                      ) : (
                        <div className="d-flex justify-content-center align-items-center">
                          <span className="mx-2">
                            <ClickButton
                              label={<>Submit</>}
                              onClick={handleSubmit}
                              disabled={loading} // Button disabled while loading
                            ></ClickButton>
                          </span>
                          <span className="mx-2">
                            <ClickButton
                              label={<>Cancel</>}
                              onClick={() =>
                                navigate("/console/master/customer")
                              }
                            ></ClickButton>
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CustomerCreation;
