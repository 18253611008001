import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Form,
  Tabs,
  Tab,
  Button,
  Offcanvas,
} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import API_DOMAIN from "../config/config";
import { FaMagnifyingGlass } from "react-icons/fa6";
import TableUI from "../components/Table";
import { DropDownUI, TextInputForm } from "../components/Forms";
import Pagnation from "../components/Pagnation";
import { ClickButton } from "../components/ClickButton";
import { useNavigate } from "react-router-dom";
import { IoFilter } from "react-icons/io5";
import { IoCloseCircle } from "react-icons/io5";
import { SlPrinter } from "react-icons/sl";

const LocationNewTablehead = [
  "No",
  "Party Name",
  "Mobile No",
  "product",
  "Quantity",
  "Action",
];
const LocationPendingTablehead = [
  "No",
  "Party Name - P",
  "Mobile No",
  "product",
  "Quantity",
  "Action",
];

const OrderForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [newOrders, setNewOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [show, setShow] = useState(false); // Offcanvas visibility

  // Offcanvas handlers
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_DOMAIN}/orderform.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "listOrders",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const responseData = await response.json();
      if (responseData.head.code === 200) {
        const allOrders = responseData.body.orders;
        setUserData(allOrders);
        filterOrders(allOrders); // Filter orders based on status
      } else {
        throw new Error(
          responseData.message ? responseData.message : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const filterOrders = (orders) => {
    const newOrderList = orders.filter((order) => order.status === "New");
    const pendingOrderList = orders.filter(
      (order) => order.status === "Pending"
    );

    setNewOrders(newOrderList);
    setPendingOrders(pendingOrderList);
  };

  return (
    <div className="main-content">
      <Container fluid>
        <Row>
          <Col lg="7" md="4" xs="6">
            <div className="page-nav py-3">
              <span className="nav-list">OrderForm</span>
            </div>
          </Col>
          <Col lg="5" md="3" xs="6" className="align-self-center text-end">
            <ClickButton
              label={<>Add New</>}
              onClick={() => navigate("/console/orderform/create")}
            ></ClickButton>
          </Col>

          <Col lg="12" md="12" xs="12" className="px-0">
            <div className="py-1">
              {loading ? (
                <center>
                  <Spinner animation="border" variant="dark" />{" "}
                </center>
              ) : (
                <TableUI
                  headers={LocationNewTablehead}
                  body={newOrders}
                  type="orderform"
                  style={{ borderRadius: "5px" }}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OrderForm;
